import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import {
  Container,
  Section,
  ContentRow,
  ContentColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  MediaWrapper,
  ArrowForward,
  ArrowRight,
  ContentButton
} from '../../../global.style';

import { Video, ButtonWrapper } from './travel.elements';

export const Travel = (props) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

  return (
    <Section inverse={props.inverse} ref={ref}>
      <Container>
        <ContentRow reverse={props.reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {props.topLine.welcome}
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.headline.welcome}
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.welcome1}
              </Subtitle>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.9, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.welcome2}
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <MediaWrapper>
              <Video src={props.video}
                alt={props.alt}
                autoPlay loop muted
                type='video/mp4 /' />
            </MediaWrapper>
          </ContentColumn>
        </ContentRow>

        <ContentRow inverse={props.inverse} style={{ gridGap: "2rem", justifyContent: "left" }}>
          <ContentColumn style={{ maxWidth: "600px" }}>
            <TextWrapper >
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {props.topLine.book}
              </TopLine>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.travel}
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
            style={{ maxWidth: "600px" }}
          >
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {props.topLine.travel}
              </TopLine>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.travel}
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
        </ContentRow>

        <ButtonWrapper
          initial={initial}
          transition={{ delay: 1, duration: 0.6 }}
          animate={animation}
          inverse={props.inverse}>
          <ContentButton
            primary={props.primary}
            secondary={props.secondary}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            href={props.route}
            target="_blank"
          >
            {props.buttonLabel}{hover ? <ArrowForward /> : <ArrowRight />}
          </ContentButton>
        </ButtonWrapper>
      </Container>
    </Section>
  );
};
