export const accommodation = {
    reverse: false,
    inverse: true,
    topLine: {
        text: "Room Suites",
    },
    headline: "Visit Raffles Istanbul",
    description: {
        paragraph1: "For room reservation and in order to benefit from special group rate for the event, you can visit Raffles Istanbul website.",
    },
    img: {
        room1: require("../assets/img/room-deluxe.jpg"),
        room2: require("../assets/img/room-skyline.jpg"),
        room3: require("../assets/img/room-premiere.jpg"),
        room4: require("../assets/img/room-executive.jpg"),
    },
    start: 'true',
    buttonLabel: "Book Now",
    route: "https://www.raffles.com/istanbul/rooms-suites/",
    primary: true,
    secondary: false
};

{/* <h6>Sheikh Zayed Rd - Dubai</h6>
          <h6>PO Box 124405, Dubai, United Arab Emirates</h6>
          <h6>+97144373333</h6>
          https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5643666825385!2d55.25212195102874!3d25.184183338328587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69db79c81fc3%3A0x85ec2b0db5d7dec2!2sAl%20Habtoor%20City!5e0!3m2!1sen!2sae!4v1628505097135!5m2!1sen!2sae */}