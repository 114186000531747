export const uaefmaCommittee = {
    reverse: false,
    inverse: true,
    topLine: "Organizing Committee",
    headline: "UAEFMA Board Members",
    data: [
        {
            shortname: "Mohammed Al Hashemi",
            position: "Chairman",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/mohammed-alhashemi.png",
        },
        {
            shortname: "Nabil Al Rahma",
            position: "Vice Chairman",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/nabil-alrahma.png",
        },
        {
            shortname: "Salman Hadi",
            position: "General Secretary",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/salman-hadi.png",
        },
        {
            shortname: "Ahoud Al Ali",
            position: "Treasurer",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/ahoud-alali.jpg",
        },
        {
            shortname: "Ahmed Ahli",
            position: "Board Member",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/ahmed-ahli.png",
        },
        {
            shortname: "Maryam Buzarak",
            position: "Board Member",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/maryam-buzarak.png",
        },
        {
            shortname: "Abdulaziz Ahmed Kashwani",
            position: "Board Member",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/abdulaziz-kashwani.png",
        },
        {
            shortname: "Eman Adel",
            position: "Event Manager",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/eman.png",
        }
    ]
};

export const gccCommittee = {
    reverse: true,
    inverse: true,
    topLine: "Country Representatives",
    headline: "GCC Representatives",
    data: [
        {
            shortname: "Hanadi Al Asmi",
            position: "Bahrain Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/hanadi-alasmi.png",
        },
        {
            shortname: "Abdulla Mohamed",
            position: "Bahrain Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/flag-bahrain.jpg",
            width: "50px"
        },
        {
            shortname: "Osama Al Harbi",
            position: "Kingdom of Saudi Arabia Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/flag-ksa.png",
            width: "50px"
        },
        {
            shortname: "Aqeel Nasser Habeeb",
            position: "Kuwait Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/aqeel-habeeb.png",
        },
        {
            shortname: "Ahmed el Samet",
            position: "Kuwait Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/flag-kuwait.png",
            width: "50px"
        },
        {
            shortname: "Bashir Said Al Subhi",
            position: "Oman Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/flag-oman.jpg",
            width: "50px"
        },
        {
            shortname: "Nabil Al Rahma",
            position: "UAE Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/nabil-alrahma.png",
        },
        {
            shortname: "Ahmed Ahli",
            position: "UAE Representative",
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/ahmed-ahli.png",
        }
    ]
};