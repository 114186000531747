export const sponsors = {
    reverse: false,
    inverse: true,
    headline: "Brought to you by",
    subtitle: "Sponsors",
    data: [
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/xm-white-logo.png",
            link: "https://www.xm.com/",
            width: "250px",
        },
        // {
        //     logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/nbd-logo.png",
        //     category: "platinum",
        //     link: "https://www.emiratesnbd.com/en",
        //     width: "280px",
        // },
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/Tullet-Logo.png",
            category: "bronze",
            link: "https://www.tullettprebon.com/",
            width: "280px",
        }
    ]
};

export const media = {
    reverse: false,
    inverse: true,
    headline: "In partnership with",
    subtitle: "Associate Partner",
    data: [
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/icma-logo.png",
            link: "https://www.icmagroup.org/",
            width: "250px",
        },
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/zero13.png",
            link: "https://www.gmex-group.com/zero13/",
            width: "200px",
        }
    ]
};

export const travel = {
    reverse: false,
    inverse: true,
    headline: "",
    subtitle: "Training Partner",
    data: [
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/ASTC-Logo.png",
            link: "https://www.astcdubai.com/",
            width: "200px",
        },
        {
            logo: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/sponsors/Beacon-Training.png",
            link: "https://beacon.com.eg/",
            width: "160px",
        }
    ]
};