
import React, { useState } from 'react'
import {
    HeroImageBackground,
    HeroImageContainer,
    ImageBackground,
    HeroImageContent,
    HeroH2,
    HeroLine
} from './hero.elements';

const HeroImage = ({ headline, img, alt }) => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <HeroImageContainer style={{ justifyContent: "left" }}>
            <HeroImageBackground>
                <ImageBackground src={img} alt={alt} />
            </HeroImageBackground>
            <HeroImageContent>
                <HeroH2>{headline}</HeroH2>
                <HeroLine />
            </HeroImageContent>
        </HeroImageContainer>
    )
}

export default HeroImage