import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from '../../../utils/useMediaBreakpoints';
export const Video = styled(motion.video)`
	padding-right: 0;
	border: 0;
	max-width: 90%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
	justify-content: flex-start;
`;

export const ButtonWrapper = styled(motion.div)`
    display: flex;
    align-items: center;

	${breakpoints("margin", "px", [{ 500: 15 }])}
`