import React, { useState } from 'react'
import { Wrap, Dropdown } from './accordion.elements'
import { FiMinus, FiPlus } from "react-icons/fi";
import { Details } from '../Agenda/details';
import { dayOne } from '../../data/agenda/day1.data';
import { dayTwo } from '../../data/agenda/day2.data';
import { dayThree } from '../../data/agenda/day3.data';
import { Section, Container } from '../../global.style';

export const Accordion = () => {

    const [clicked, setClicked] = useState(false);

    const toggle = (index) => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    }

    const days = ['13th Sep', '14th Sep', '15th Sep'];

    const getAgendaDetails = (clicked) => {
        switch (clicked) {
            case 0:
                return ([
                    <Details {...dayOne} />,
                ]);
            case 1:
                return ([
                    <Details {...dayTwo} />,
                ]);
            case 2:
                return ([
                    <Details {...dayThree} />,
                ]);
        }
    }

    return (
        // <Section>
        <Container>
            {days.map((item, index) => (
                <>
                    <Wrap onClick={() => toggle(index)} key={index}>
                        <h1>{item}</h1>
                        <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                    </Wrap>
                    {clicked === index ? (
                        <Dropdown style={{ paddingLeft: "0px" }}>
                            {getAgendaDetails(clicked)}
                        </Dropdown>
                    ) : null
                    }
                </>
            ))}
        </Container>
        // </Section>
    )
}