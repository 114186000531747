import styled from 'styled-components';
import { breakpoints } from '../../../utils/useMediaBreakpoints';

export const Content = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  ${breakpoints("display", "", [{ 800: "flex" }])}
  ${breakpoints("flex-direction", "", [{ 800: "column" }])}

`
export const ContentTraining = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${breakpoints("display", "", [{ 800: "flex" }])}
  ${breakpoints("flex-direction", "", [{ 800: "column" }])}

`
export const ContentMedia = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${breakpoints("display", "", [{ 800: "flex" }])}
  ${breakpoints("flex-direction", "", [{ 800: "column" }])}

`

export const Avatar = styled.a`
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: grayscale(100%);

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
        text-decoration: none;
    filter: grayscale(0%);
  }
`

export const AvatarInfo = styled.div`
`

export const AvatarIcon = styled.img`
  align-items: center;
  justify-content: center;
`
