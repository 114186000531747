import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    color: #F4F4F4;

    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    text-align: center;
    color: #F4F4F4 !important;
    letter-spacing: 3px;
    text-transform: uppercase;
`
export const RegistrationClose = styled.p`
    text-align: center;
    color: #F4F4F4 !important;
    letter-spacing: 3px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-size: 48px !important;
    }
`

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

export const Card = styled(Link)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(11, 28, 58, 0.2);
  width: 280px;
  height: 420px;
  text-decoration: none;
  /* border-radius: 10px; */

  &:nth-child(2) {
    margin: 24px;
  }
  
  &:hover {
    background: #313886;
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
      text-decoration: none;
      color: #2b2b2b;
    }
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  color: #F4F4F4;
  
  @media screen and (max-width: 768px) {
        padding: 66px;
    }
`

export const CardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`

export const CardCost = styled.h4`
  font-size: 70px;
`

export const Discount = styled.h4`
  font-size: 70px;
  color: #01bf71;
`

export const CardLength = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
`

export const Currency = styled.span`
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  vertical-align: 2rem;
`

export const CardInclusions = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;

  padding-inline-start: 0px;
`

export const CardInclusion = styled.li`
  margin-bottom: 10px;
`