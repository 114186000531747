import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { Container, Section } from '../../global.style';
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ScheduleWrapper,
  Time,
  Title,
  ContentColumn,
  Description,
  DescriptionWrapper,
  DiscussionTitle
} from './details.elements';

export const Details = (props) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section inverse={props.inverse} ref={ref}>
      <Container>
        <ContentRow reverse={props.reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {props.topLine}
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.headline}
              </Heading>
              {props.schedule.map((item, key) => (
                <ScheduleWrapper
                  initial={initial}
                  transition={{ delay: 0.7, duration: 0.6 }}
                  animate={animation}
                  inverse={props.inverse}
                >
                  <Time>{item[0]}</Time>
                  <Title>{item[1]}</Title>
                  {item[2] || item[3] ?
                    <DescriptionWrapper>
                      <DiscussionTitle>{item[2]}</DiscussionTitle>
                      <Description>{item[3]}</Description>
                    </DescriptionWrapper> : <></>}
                </ScheduleWrapper>

              ))}
            </TextWrapper>
          </ContentColumn>
        </ContentRow>
      </Container>
    </Section>
  );
};
