import React from "react";
import HeroImage from "../components/Hero/hero.image";
import { tickets } from "../data/hero.data";
import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";
import { Tickets as TicketCard } from "../components/Tickets";

const Tickets = () => {
  const metaData = getMetaData("tickets");

  return (
    <>
      <HeroImage {...tickets} style={{ width: "10px" }} />
      <MetaTags metaData={metaData} />
      <TicketCard />
    </>
  )
}

export default Tickets