import React, { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import Cards, { membership } from "./Cards";
import PaymentMethods, { paymentMethods } from "./PaymentMethods";

const Form = () => {

  console.log("console1: ", process.env.REACT_APP_MY_URI);

  const [country, setCountry] = useState();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = useForm();
  const [spouse, setSpouse] = useState(false);
  const [type, setType] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [phone, setPhone] = useState("");
  const [spousePhone, setSpousePhone] = useState();

  const history = useHistory();

  const handleSpouse = () => {
    setSpouse(!spouse);
  };
  const [coupon, setCoupon] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);

  const handleCoupon = async (couponInput) => {
    try {
      setIsValidating(true);
      let res = await fetch(
        `${process.env.REACT_APP_MY_URI}/promos/${couponInput}`
      );

      coupon.code = "uaefma5";
      coupon.discountInPercent = "5";

      if (!couponInput || coupon.code !== couponInput) {
        setCouponError(true);
        setCouponSuccess(false);
        console.log("Invalid Coupon");
        setIsValidating(false);
      } else {
        console.log(couponInput);
        await setCoupon({
          discountInPercent: coupon.discount,
          code: coupon.code,
        });
        setCouponError(false);
        setCouponSuccess(true);
        setIsValidating(false);
      }

    } catch (e) {
      console.log(e);
      setCouponError(true);
      setCouponSuccess(false);
      setIsValidating(false);
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    if (paymentMethod == null) {
      alert("Please select a payment method")
      return
    }
    if (!phone) {
      alert("Invalid phone number");
      return "Invalid phone number";
    }

    data.typeOfRegister = type == 0 ? "DELEGATE" : "UAEFMA";
    data.paymentType = paymentMethod == 0 ? "ONLINE" : "BANK";
    data.country = country;
    data.phone = phone;

    console.log(data);

    if (spousePhone) data.spousePhone = spousePhone;
    try {
      let res = await fetch(`${process.env.REACT_APP_MY_URI}/register`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      res = await res.json();
      await console.log(res);

      if (res.url) {
        window.location = res.url;
      } else if (res.id) {
        alert(
          "Registration Complete! Please Check your mail box for confirmation"
        );
        history.push("/");
      } else if (res.message) {
        alert("Email already registered, please use different email");
      } else {
        alert("Something went wrong please try again");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePhone = (isValid, newNumber, fullNumber) => {
    setPhone(fullNumber.dialCode + newNumber.substring(1));
  };
  const handleSpousePhone = (isValid, newNumber, fullNumber) => {
    setSpousePhone(fullNumber.dialCode + newNumber.substring(1));
  };

  return (
    <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
      {/* <h1>Register</h1> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-left">
            <Cards
              onChange={(selected) => {
                setType(selected);
              }}
              cardContents={membership}
              type={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              ref={register}
              required
            />
          </div>
          <div className="col-md-6">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              ref={register}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="email"
              name="email"
              placeholder="Email"
              ref={register}
              required
            />
          </div>
          <div className="col-md-6">
            <CountryDropdown
              defaultOptionLabel="Country of Residence"
              style={{ color: "#777" }}
              value={country}
              onChange={(val) => setCountry(val)}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <IntlTelInput
              containerClassName="intl-tel-input"
              preferredCountries={["ae"]}
              onPhoneNumberChange={handlePhone}
              // handleInputChange={handlePhone}
              telInputProps={{ required: true }}
              fieldName="phone"
            />
          </div>

          <div className="col-md-6">
            <input
              type="text"
              name="position"
              placeholder="Position/Job Title"
              ref={register}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <input
              type="text"
              name="institution"
              placeholder="Bank/Institution"
              ref={register}
              required
            />
          </div>
        </div>
        <div style={{ marginTop: "1.5rem" }} className="row">
          <input
            value={spouse}
            type="checkbox"
            name="toggleSpouse"
            onChange={handleSpouse}
          />
          <label htmlFor="toggleSpouse">Accompanying Spouse</label>
        </div>
        {spouse && (
          <>
            <div className="row">
              <div
                style={{
                  marginLeft: "1rem",
                  fontSize: "0.85rem",
                  textAlign: "left",
                }}
              >
                *Spouse can attend the social activities with a registered
                delegates. As well as some other free activities will be
                available during conference. Spouse will not be admitted to the
                conference sessions.
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name="spouseFirstName"
                  placeholder="Spouse First Name"
                  ref={register}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name="spouseLastName"
                  placeholder="Spouse Last Name"
                  ref={register}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  preferredCountries={["ae"]}
                  name="phone"
                  onPhoneNumberChange={handleSpousePhone}
                  telInputProps={{ required: true }}
                />
              </div>
              <div className="col-md-6"></div>
            </div>
          </>
        )}
        {/* <div style={{ marginTop: "1.5rem" }} className="row">
          <div className="col-md-12 text-left">
            <h3>How did you hear about us?</h3>
          </div>
        </div> */}
        <div className="row ">
          <div className="col-md-6">
            <select
              className="form-select"
              aria-label="Default select example"
              name="source"
              ref={register}
            >
              <option defaultValue="" value="">
                How did you hear about us?
              </option>
              <option value="linkedIn">LinkedIn</option>
              <option value="facebook">Facebook</option>
              <option value="instagram">Instagram</option>
              <option value="newsletter">Newsletter</option>
              <option value="friend">Friend</option>
              <option value="difc">DIFC</option>
            </select>
          </div>
        </div><div className="row text-left">

          {/* <PaymentMethods
            title="Payment Mehtod"
            onChange={(selected) => {
              setPaymentMethod(selected);
            }}
            cardContents={paymentMethods} /> */}
          <Cards
            title="Payment Method"
            onChange={(selected) => {
              setPaymentMethod(selected);
            }}
            cardContents={paymentMethods}
          />
        </div>
        {type == 0 && (
          <div className="row">
            <div className="col-md-6">
              <input
                name="promoCode"
                placeholder="Promo Code (Optional)"
                type="text"
                ref={register}
              />
              {couponError && (
                <div align="left" style={{ color: "red", fontSize: "1rem" }}>
                  {" "}
                  Invalid Coupon
                </div>
              )}
              {couponSuccess && (
                <div align="left" style={{ color: "green", fontSize: "1rem" }}>
                  {" "}
                  Coupon applied successfully
                </div>
              )}
              {!couponError && coupon.discountInPercent && (
                <div align="left" style={{ color: "green", fontSize: "1rem" }}>
                  - ${(1800 * coupon.discountInPercent) / 100}
                </div>
              )}
            </div>
            <div className="col-md-3">
              {isValidating ? (
                <CircularProgress />
              ) : (
                <input
                  type="button"
                  onClick={() => handleCoupon(getValues("promoCode"))}
                  style={{ marginBottom: "1rem" }}
                  disabled={isSubmitting}
                  value="Apply"
                />
              )}
            </div>
          </div>
        )}
        <input
          style={{ marginBottom: "1rem" }}
          disabled={isSubmitting}
          value="Register"
          type="submit"
        />
      </div>
    </form>
  );
};
export default Form;