export const tutorial = {
    reverse: false,
    inverse: true,
    topLine: "Through the years",
    headline: "Conferences by UAEFMA",
    data: [
        {
            title: "3rd GCCFMT Conference",
            videoLink: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/videos/GCC+FMT+Promo+Video+HD.mp4",
            alt: "Conference Video",
            thumbnail: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/videos/Video+Thumbnail.png"
        }
    ]
};
