import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Avatar = styled(Link)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(11, 28, 58, 0.2);
  width: 200px;
  height: 200px;
  text-decoration: none;
  border-radius: 10px;
  padding: 0;
  
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
        text-decoration: none;
  }
`

export const AvatarInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 50px;
  align-items: center;
  color: #F4F4F4;
`

export const AvatarIcon = styled.div`
`

export const AvatarName = styled.h3`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`