import React from "react";
import HeroImage from "../components/Hero/hero.image";
import { speakers as heroData } from "../data/hero.data";
import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";
import { Speakers as Component } from "../components/Speakers";
import { speakers } from "../data/speakers.data";

const Speakers = () => {
  const metaData = getMetaData("speakers");

  return (
    <>
      <HeroImage {...heroData} style={{ width: "10px" }} />
      <MetaTags metaData={metaData} />
      <Component {...speakers} />
    </>
  )
}

export default Speakers