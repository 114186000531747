
import React from 'react'
import useWindowDimensions from '../../utils/useWindowDimensions';
import { TextWrapper } from "../../global.style";
import {
  HeroBackground,
  HeroContainer,
  VideoBackground,
  HeroContent,
  HeroH1,
  HeroParagraph,
  MediaWrapper,
  Img
} from './hero.elements';
import { hero } from '../../data/hero.data';

const HeroVideo = () => {
  const { height, width } = useWindowDimensions();

  return (
    <HeroContainer>
      <HeroBackground>
        <VideoBackground src={hero.img} alt={hero.alt} autoPlay loop muted type='video/mp4 /' />
      </HeroBackground>
      <HeroContent>
        <TextWrapper>
          <HeroH1>{hero.headline}</HeroH1>
        </TextWrapper>
        {width > 1200 ? (
          <>
            <MediaWrapper style={{ paddingTop: "50px", justifyContent: "flex-start" }}>
              <Img
                src={hero.logo}
                alt={hero.alt}
              />
            </MediaWrapper>
            <HeroParagraph>
              {hero.description}
            </HeroParagraph>
          </>) : (
            <>
              <HeroParagraph>
                {hero.description}
              </HeroParagraph>
              <MediaWrapper>
                <Img
                  src={hero.logo}
                  alt={hero.alt}
                />
            </MediaWrapper>
          </>)}
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroVideo