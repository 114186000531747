import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link } from 'react-scroll';
import { FaTimes } from 'react-icons/fa';

export const SideBarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    background: #313886;
    display: grid;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: #F4F4F4;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    outline: none;
    cursor: pointer;
`

export const SideBarWrapper = styled.div`
    color: #F4F4F4;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const SideBarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-inline-start: 0px;
`

export const SideBarLink = styled(LinkRouter)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:#F4F4F4;
    cursor: pointer;
    text-transform: uppercase;
    padding-bottom: 10px;

    &:hover {
        color: #F4F4F4;
        transition: 0.2s ease-in-out;
        font-weight: 900;
        text-decoration: none;
    }
`

export const SideBarButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const SideBarRoute = styled(LinkRouter)`
    border-radius: 10px;
    background: #F4F4F4;
    white-space: nowrap;
    padding: 10px 35px;
    color: #2B2B2B;
    font-size: 14px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    padding-top: 13px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2B2B2B;
        color: #F4F4F4;
        text-decoration: none;
    }
`