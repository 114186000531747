export const venue = {
    reverse: false,
    inverse: true,
    topLine: {
        text: "The Venue",
        forRate: "FOR CORPORATE RATES PLEASE CONTACT",
    },
    headline: "Movenpick Hotel Istanbul Bosphorus",
    description: {
        paragraph1: "Mövenpick Bosphorus Istanbul, where modernity and exclusivity define every space you come across. As our guest, you will only experience the finest, from deluxe accommodation to incomparable gastronomic delights and incredible Istanbul skyline views to our rejuvenating wellness & fitness center. Located in the heart of Istanbul, our elegant and welcoming rooms and suites offers a tranquil retreat from the bustling city. They include fast and free Wi-Fi, furnitures made from natural material, space and many more. Premium bedding, ergonomic beds and blackout curtains ensure a peaceful nights rest.",
        email: "Email: eman@uaefma.com",
        phone: "Phone: (+971) 42566622"
    },
    img: require("../assets/img/movenpick-istanbul-logo.png"),
    start: 'true',
    buttonLabel: "Find Out More",
    route: "https://movenpick.accor.com/en/europe/turkey/istanbul/movenpick-hotel-stanbul-bosphorus.html",
    primary: true,
    secondary: false
};

{/* <h6>Sheikh Zayed Rd - Dubai</h6>
          <h6>PO Box 124405, Dubai, United Arab Emirates</h6>
          <h6>+97144373333</h6>
          https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5643666825385!2d55.25212195102874!3d25.184183338328587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69db79c81fc3%3A0x85ec2b0db5d7dec2!2sAl%20Habtoor%20City!5e0!3m2!1sen!2sae!4v1628505097135!5m2!1sen!2sae */}