import React from 'react';
import YoutubeEmbed from '../../Youtube';
import { CardContainer, Section, TopLine, Heading, CardSection} from '../../../global.style';
import { Content } from './gallery.elements';

export const Gallery = (props) => {
    return (
        <>
            <CardSection inverse={props.inverse} style={{ alignItems: "center"}}>
                <CardContainer>
                    <TopLine>{props.topLine}</TopLine>
                    <Heading inverse={props.inverse}>{props.headline}</Heading>
                    <Content>
                        {props.data.map(s => (
                            <YoutubeEmbed embedId={s.embed} title={s.title} />
                        ))}
                    </Content>
                </CardContainer>
            </CardSection>
        </>
    );
}