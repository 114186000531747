import React from "react";
import HeroImage from "../components/Hero/hero.image";
import InformationTabs from "../components/Information";
import { information } from "../data/hero.data";
import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";

const Information = () => {
    const metaData = getMetaData("information");

    return (
        <>
            <HeroImage {...information} style={{ width: "10px" }} />
            <MetaTags metaData={metaData} />
            <InformationTabs inverse={true} />
        </>
    )
}

export default Information