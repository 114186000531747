import React, { useState } from "react";
import {
    Container,
    ContentButton,
    ContentButtonRegistration,
    Img,
    MediaWrapper,
    Section,
} from "../../../global.style";

import {
    Heading,
    Subtitle,
    CardContent,
    Card,
    CardInfo,
    CardPlanMain,
    CardPlan,
    CardLength,
} from "./course.elements";
import img from "../../../assets/dont-miss-hero.png";
import { FaCheck } from "react-icons/fa";

export const Courses = (props) => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

    const [flipped, setFlipped] = useState(false);

    const styles = {
        card: {
            transformStyle: "preserve-3d",
            transition: "all 0.5s ease",
            transform: !flipped ? "rotateY(0deg)" : "rotateY(180deg)",
        },
        front: {
            height: "100%",
            width: "100%",
            backfaceVisibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        },
        back: {
            height: "100%",
            width: "100%",
            backfaceVisibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#242424",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "rotateY(180deg)",
        },
    };

    return (
        <>
            <Section style={{ background: "#f4f4f4" }}>
                <Container>
                    <Heading style={{ fontWeight: "700" }}>
                        {props.topLine}
                    </Heading>
                    <Subtitle style={{ color: "#242424 " }}>
                        <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>Register Now </span>to take advantage of this limited-time offer to enroll in <span style={{ fontWeight: "bold" }}> 1 of the 4 </span>exclusive training programs, absolutely <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>FREE </span>of charge. Don’t delay, secure your <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>spot today!</span>
                    </Subtitle>
                    <CardContent>
                        {props.data.map((course) => (
                            <Card style={styles.card}>
                                <CardInfo style={styles.front}>
                                    <MediaWrapper style={{ marginTop: "16px" }}>
                                        <Img src={course.timage} />
                                    </MediaWrapper>
                                    <CardPlan>{course.coursetitle}</CardPlan>

                                    <CardLength>{course.coursespeaker}&nbsp;</CardLength>
                                    <CardPlanMain>
                                        <CardPlan style={{ color: "#01bf71", textDecoration: "line-through" }}>{course.cost}</CardPlan>
                                        <CardPlan style={{ color: "#01bf71" }}>{course.free}</CardPlan>
                                    </CardPlanMain>
                                    <span>&nbsp;</span>
                                    <ContentButton
                                        primary={props.primary}
                                        secondary={props.secondary}
                                        onMouseEnter={onHover}
                                        onMouseLeave={onHover}
                                        onClick={() => {
                                            window.open(course.route);
                                        }}
                                    >
                                        {props.buttonLabel}
                                    </ContentButton>
                                    <span>&nbsp;</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </CardInfo>
                            </Card>
                        ))}

                    </CardContent>
                    <Container style={{ margin: "26px" }}>
                        <ContentButtonRegistration style={{ color: "white", padding: "16px 52px", transform: "translate(-50%, -50%)" }}
                            primary={props.primary}
                            secondary={props.secondary}
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            href="/tickets"
                        >
                            {props.btnheadline}
                        </ContentButtonRegistration>
                    </Container>
                </Container>



            </Section>
        </>
    );
};
export default Courses;