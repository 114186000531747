export const course = {
    inverse: true,
    topLine: "LIMITED TIME OFFER",
    btnheadline: "Early Bird Registration",
    buttonLabel: "Learn More",
    primary: true,
    secondary: false,
    data: [
        {
            coursetitle: "FX Options & Structured Products Seminar",
            coursespeaker: "Dr.Uwe Wystup",
            route: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/courses/FX+Option+GCC.pdf",
            cost: "950 USD",
            free: "Free",
            timage: require("../assets/course/Dr-Uwe-GCC-Frame.png")
        },
        {
            coursetitle: "Technical Analysis     ",
            coursespeaker: "Arvamis Despotis",
            route: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/courses/Technical+Analysis+GCC.pdf",
            cost: "950 USD",
            free: "Free",
            timage: require("../assets/course/Arvamis-GCC-Frame.png")
        },
        {
            coursetitle: "Financial Derivatives",
            coursespeaker: "Dr.Mahendra Mehta",
            route: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/courses/Financial+Derivatives+GCC.pdf",
            cost: "950 USD",
            free: "Free",
            timage: require("../assets/course/Dr-Mahendra-GCC-Frame.png")
        },
        {
            coursetitle: "Fixed Income & Repo Workshop",
            coursespeaker: "Andre Kurten",
            route: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/courses/Fixed+Income+and+Repo+Workshop.pdf",
            cost: "950 USD",
            free: "Free",
            timage: require("../assets/course/Andre-Kurten-GCC-Frame.png")
        }
    ]
};
