import React from 'react';
import { Container, Section, Heading, TopLine, ContentColumn } from '../../../global.style';
import {
    Content,
    ContentTraining,
    ContentMedia,
    Avatar,
    AvatarIcon,
} from './sponsors.elements';
import { media, travel } from '../../../data/sponsors.data';
export const Media = (inverse) => {
    return (
        <>
            <Section inverse={inverse}>
                <Container>
                    <TopLine>{media.headline}</TopLine>
                    <Content style={{ display: "flex" }}>
                        <ContentColumn>
                            <Heading inverse={inverse}>{media.subtitle}</Heading>
                            <ContentMedia>
                                {media.data.map(s => (
                                    <Avatar href={s.link} target="_blank">
                                        <AvatarIcon src={s.logo} width={s.width} />
                                    </Avatar>
                                ))}
                            </ContentMedia>
                        </ContentColumn>

                        <ContentColumn>
                            <Heading inverse={inverse}>{travel.subtitle}</Heading>
                            <ContentTraining>
                                {travel.data.map(s => (
                                    <Avatar href={s.link} target="_blank">
                                        <AvatarIcon src={s.logo} width={s.width} />
                                    </Avatar>
                                ))}
                            </ContentTraining>
                        </ContentColumn>
                    </Content>
                </Container>
            </Section>
        </>
    );
}