const getMetaData = (slug) => {
  var metaData = {
    title: "5th GCC FMT Conference",
    description: "",
    keywords: "GCC FMT",
    robots: "",
    canonicalUrl: "https://www.gccfmt.com",
  };

  switch (slug) {
    case "register":
      metaData.title = "Registration | 5th GCC FMT Conference ";
      metaData.description = "Registration | 5th GCC FMT Conference";
      metaData.keywords = "Registration | 5th GCC FMT Conference";
      metaData.canonicalUrl = "https://www.gccfmt.com/postregister";
      return metaData;
    case "agenda":
      metaData.title = "Agenda | 5th GCC FMT Conference ";
      metaData.description = "Agenda | 5th GCC FMT Conference";
      metaData.keywords = "Agenda | 5th GCC FMT Conference";
      metaData.canonicalUrl = "https://www.gccfmt.com/agenda";
      return metaData;
    case "information":
      metaData.title = "Information | 5th GCC FMT Conference ";
      metaData.description = "Information | 5th GCC FMT Conference";
      metaData.keywords = "Information | 5th GCC FMT Conference";
      metaData.canonicalUrl = "https://www.gccfmt.com/information";
      return metaData;
    case "speakers":
      metaData.title = "Speakers | 5th GCC FMT Conference ";
      metaData.description = "Speakers | 5th GCC FMT Conference";
      metaData.keywords = "Speakers | 5th GCC FMT Conference";
      metaData.canonicalUrl = "https://www.gccfmt.com/speakers";
      return metaData;
    case "policy":
      metaData.title = "Privacy Policy | 5th GCC FMT Conference ";
      metaData.description = "Privacy Policy | 5th GCC FMT Conference ";
      metaData.keywords = "Privacy Policy | 5th GCC FMT Conference ";
      metaData.canonicalUrl = "https://www.gccfmt.com/policy";
    default:
      return metaData;
  }
};

export default getMetaData;
