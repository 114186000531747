import React from 'react';
import { Container, Section, Heading, TopLine } from '../../../global.style';

import {
    Content,
    Avatar,
    AvatarIcon,
} from './sponsors.elements';

export const Sponsors = (props) => {
    return (
        <>
            <Section inverse={props.inverse}>
                <Container>
                    <TopLine>{props.headline}</TopLine>
                    <Heading inverse={props.inverse}>{props.subtitle}</Heading>
                    <Content>
                        {props.data.map(s => (
                            <Avatar href={s.link} target="_blank">
                                <AvatarIcon src={s.logo} width={s.width} />
                            </Avatar>
                        ))}
                    </Content>
                </Container>
            </Section>
        </>
    );
}