export const travel = {
    reverse: false,
    inverse: true,
    topLine: {
        welcome: "Welcome To Turkey",
        book: "Before you book",
        travel: "Before you travel",
    },
    headline: {
        welcome: "Türkiye'ye hoşgeldiniz",
    },
    description: {
        welcome1: "Türkiye is home to infinite locations that are beyond your wildest dreams! Full of ancient sites, unique beauty, and unparalleled destinations, visiting Türkiye is an unforgettable experience.",
        welcome2: "A country that occupies a unique geographic position, lying partly in Asia and partly in Europe. Throughout its history it has acted as both a barrier and a bridge between the two continents.",
        book: "Check if you need a visa. Depending on your nationality you can get  a visa on arrival, or you can apply for your prearranged visit visa from Sky Fly before you travel.",
        travel: "Check if you need a visa. Depending on your nationality you can get  a visa on arrival, or you can apply for your prearranged visit visa from Sky Fly before you travel."
    },
    video: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/videos/welcome-to-turkey.mp4",
    start: 'true',
    buttonLabel: "Find Out More",
    route: "https://goturkiye.com/",
    primary: true,
    secondary: false
};