import React from "react";
import HeroVideo from "../components/Hero/hero.video";
import HomePage from "../components/HomePage";
import { hero } from "../data/hero.data";
import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";

const Home = () => {
  const metaData = getMetaData("home");

  return (
    <>
      <HeroVideo {...hero} />
      <MetaTags metaData={metaData} />
      <HomePage />
    </>
  )
}

export default Home