import React, { useState } from 'react';
import { Introduction } from './Introduction';
import { Ticket } from './Ticket';
import { Speakers } from './Speakers';
import { Sponsors } from './Sponsors';
import { Courses } from './Courses';
import { speakers } from '../../data/speakers.data';
import { sponsors } from '../../data/sponsors.data';
import { ticket } from '../../data/ticket.data';
import { course } from '../../data/course.data';
import { introduction } from '../../data/introduction.data';
import { Media } from './Sponsors/media';
import { Tutorial } from '../Tutorial';
import { tutorial } from '../../data/tutorial.data';


const HomePage = () => {
    return (
        <>
            <Introduction {...introduction} />
            <Courses {...course} />
            <Tutorial {...tutorial} />
            <Speakers {...speakers} />
            <Sponsors {...sponsors} />
            <Media inverse={true} />
            <Ticket {...ticket} />
        </>
    )
}

export default HomePage