export const speakers = {
    reverse: false,
    inverse: true,
    topLine: "This Year's Panel",
    headline: "Meet the Speakers ",
    data: [
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/mohammed-alhashemi.png",
            shortname: "Mohammed Al Hashemi",
            position: "",
            bio: "Mohammed Al Hashemi, Chairman and Co-founder of UAE Financial Markets Association, has transformed the organisation into a regional knowledge hub with operational excellence. With 20 years of banking expertise in treasury and capital markets, Mohammed is respected for his decision-making abilities. As Senior Director of Global Markets and Treasury at Emirates NBD, he achieved milestones and improved performance at Emirates Islamic. Previously, he worked at British Bank of Middle East (now HSBC Dubai). Mohammed holds an MSc in International Banking and Securities from Reading University, UK, and a BSc in Business Administration from Eastern Connecticut State University, USA. He is a SCI Certified Treasury Professional actively pursuing further development."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Andre_Kurten.png",
            shortname: "Andre Kurten",
            position: "",
            bio: "André is a highly accomplished professional with a strong background in Foreign Exchange Derivatives. With over 20 years of experience in senior management positions, including roles at Nedcor and ABSA, André possesses extensive knowledge in banking, treasury, and finance products. He is a recognized training provider for the ACI Financial Markets Association, showcasing his teaching prowess. André's passion for the industry is evident through his contributions as a published author and his dedication to delivering workshops and interventions across various countries. His expertise and leadership in the commodity industry make him a valuable asset to any organisation or event."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/committees/ahoud-alali.jpg",
            shortname: "Ahoud Al Ali",
            position: "",
            bio: "Mrs. Ahoud Al Ali is a Senior Financial Advisor at the Department of Finance in Dubai, in addition to being the Treasurer of the UAE Financial Markets Association. Mrs. Ahoud has achieved continuous success throughout her journey by achieving important achievements such as being the first Emirati woman to head the foreign exchange trading desk at Emirates NBD Bank and in the United Arab Emirates, in addition to being the only Emirati woman to manage the money market fund and trade finance funds at National Bank of Abu Dhabi. Ms. Ahoud is currently continuing her studies in Doctorate in Business Administration focusing in Finance & Investments."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Tom_James.png",
            shortname: "Dr. Tom James",
            position: "",
            bio: "Tom James is the CEO & CIO and co-founder of Trade Flow Funds, where he spearheads an innovative digitised trade finance solution for global bulk commodity transactions. With over two years of FinTech development, Tom successfully launched the Digital Trade Finance Fund in 2018, revolutionising physical commodity trade and inventory finance. As the business and system architect, he combines industry needs, risk management, and FinTech advancements for scalability. With 30+ years of experience in trading, investment management, and advisory, Tom consistently blends technology with industry best practices. His influential contributions to the blockchain industry, as recognized by various organisations, further demonstrate his expertise and leadership in the field."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Avramis-Despotis.png",
            shortname: "Avramis Despotis",
            position: "",
            bio: "Avramis Despotis is the Founder and CEO of Tradepedia, a highly experienced educator, price action trading authority, and personal coach in the financial markets. With over 20 years of expertise in technical analysis, risk management, and behavioural finance, Avramis has mentored over 40,000 traders worldwide, including those from top-tier global firms such as Reuters, HSBC, and Deutsche Bank. Holding certifications as a Certified Financial Technician and in Portfolio Management and Own Account Trading, Avramis founded Tradepedia in 2009. He leads a team of 20 instructors, offering comprehensive education, automated software, market reports, and signals to empower traders with informed decision-making capabilities."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Uwe-Wystup.png",
            shortname: "Prof. Uwe Wystup",
            position: "",
            bio: "Uwe is a renowned expert in Foreign Exchange (FX) options, pricing, risk management, and consulting. With a PhD in Mathematical Finance from Carnegie Mellon University and extensive experience in senior roles at major financial institutions such as Citibank and UBS, Uwe brings a wealth of knowledge to the table. As the managing director of MathFinance since 2003, he leads an international team dedicated to delivering currency derivatives projects worldwide. Uwe's expertise as an expert witness in financial disputes, combined with his certifications and academic achievements, positions him as a trusted authority in the field."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Serhan-Salman.png",
            shortname: "Serhan Salman",
            position: "",
            bio: "Serhan Salman is the Chief Economist and Head of Economic Research, Strategy, and Project Management Group at DenizBank. With over 18 years of experience in banking, Serhan holds expertise in macroeconomics and the financial sector in Turkey. He started his career at Garanti Bank before joining DenizBank in 2012, where he has held various roles including Internal Auditor. As a registered member of the Istanbul Chamber of CPAs and a holder of a CPA certificate, Serhan has a special interest in financial control and accounting. He earned his bachelor's degree in Economics from Istanbul University, followed by a master's degree in International Economics. Serhan Salman's extensive knowledge and diverse experience contribute to his role as Chief Economist at DenizBank."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Erkan+Kilimci.png",
            shortname: "Erkan Kilimci",
            position: "",
            bio: "Erkan Kilimci brings 20 years of experience in the financial sector to his role as Deputy Governor and Executive Director at the Central Bank of the Republic of Turkey. With a BS degree in Political Science and International Relations, a master's degree in Finance and Accounting, and certifications in various financial disciplines, Erkan's expertise is well-rounded. He co-founded the Turkish Capital Market Professionals Society and has authored articles and a book on derivative products. Erkan's deep knowledge and diverse skill set enable him to make significant contributions to the financial sector, embedding ESG principles into business strategies and driving sustainability initiatives."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Vijay+Bains.png",
            shortname: "Vijay Bains",
            position: "",
            bio: "Vijay Bains is the Chief Sustainability Office (CSO) and Group Head of Environmental, Social and Governance (ESG). With over 15 years of sustainability experience across the Middle East, UK, Europe, and North America, Vijay has held senior positions at renowned organisations such as KPMG, Ernst and Young, Lloyds Banking Group, and the European Bank of Reconstruction & Development. He specialises in designing and implementing ESG strategies, including sustainable finance, green bonds, and sustainability advisory for IPOs. At Emirates NBD, Vijay is responsible for driving the end-to-end ESG strategy, introducing sustainable practices, policies, and outcomes. His goals include expanding the bank's sustainable finance offerings, increasing sustainability investments, providing advisory services for net-zero transition, and ensuring compliance with corporate governance requirements. Vijay's role is crucial in integrating ESG principles into the bank's business strategy, aligning with the institution's sustainable vision and purpose."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Bulent+Tekmen.png",
            shortname: "Bulent Tekmen",
            position: "",
            bio: "Bulent is a Serial Entrepreneur known for his successful e-money company, ininal.com, which has over 5 million accounts and 2 million active digital wallet users monthly. He is focused on transforming the banking industry and micro-credit sector through digitalization. Bulent's vision led to the establishment of Colendi, a solution aimed at revolutionizing the industry. He is an active investor and supports early-stage FinTech start-ups through NextVentures. Bulent has been recognized with awards such as Internet Entrepreneur of the Year in Turkey and the EY Entrepreneur Of The Year program. With a background in Financial Technology Consultancy and Software development, Bulent holds a B.S. degree in Industrial Engineering and has studied at Harvard Business School."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Hirander-Misra.png",
            shortname: "Hirander Misra",
            position: "",
            bio: "Chairman & CEO of GMEX Group & ZERO13. Hirander has extensive electronic trading and FinTech expertise spanning 25 years, with successful syndication to investors and substantial exits. He is Co-Founder of market infrastructure provider GMEX Group as well as its climate fintech initiative ZERO13. Hirander is one of the Top 10 influential business leaders of blockchain technology in the All-Party Parliamentary Group 2018 and 2021 reports on the UK Blockchain industry. He is also In LATTICE80’s Top 100 influencer list for the UN Sustainable Developments Goal agenda for pioneering blockchain technology solutions. He is featured in the Financial Technologist list of the 50 most game changing founders of FinTech companies."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Vedat+Akgiray.png",
            shortname: "Prof Vedat Akgiray",
            position: "",
            bio: "Vedat Akgiray is a distinguished Professor of Finance and Director of the Center for Applied Research in Finance at Bogazici University in Istanbul, Turkey. With extensive experience in academia, he has advised numerous graduate students, published over a hundred academic papers, and directed key programs in finance. From 2009 to 2013, Vedat served as the Chairman of the Capital Markets Board of Turkey, playing a vital role in shaping the country's financial regulatory landscape and contributing to international regulatory architecture. His current research interests encompass economic-value-based corporate governance, sustainability disclosure, financial risks associated with climate change, emission trading systems, and digital disruptions in finance. He is the author of the book Good Finance, which explores the causes of financial crises and offers insights into transforming finance into a more humane discipline. Vedat is currently working on a new book advocating for significant reforms in the financial system to support the global fight against climate change."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Selva+Behan.png",
            shortname: "Dr. Selva Baziki",
            position: "",
            bio: "Dr. Selva Baziki is the Turkey and Sweden economist for Bloomberg Economics. Previously, Baziki was the Director of Macro Financial Analysis at the Central Bank of the Republic of Turkey and worked at the Federal Reserve Bank of Boston, USA and the Research Institute of Industrial Economics, Sweden. Baziki holds a Ph.D. in Economics from Uppsala University, Sweden, a B.A. in Economics from Bryn Mawr College, USA and is a graduate of Robert College, Istanbul. Her research focuses on the interaction of the macro economy with empirical banking, international finance, and trade."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Mr+Akilas.png",
            shortname: "Akilas Mavrakis",
            position: "",
            bio: "Akilas Mavrakis has over 20 years of diverse experience in Capital Markets, Corporate Finance, and Regulatory Compliance. He began as a Financial Analyst at Freddie Mac and later worked at Deutsche Bank in New York, gaining expertise in fixed-income trading, structuring, and hedging strategies. He also served as a Senior Market Risk Analyst at FHFA, overseeing mortgage investment portfolios. Currently, Akilas works as an M&A Associate at PeopleCert, focusing on corporate development strategy. Additionally, he teaches Finance, Financial Modeling, and Financial Statement Analysis at Georgetown University as an adjunct faculty member. Akilas holds an M.S. in Finance from Johns Hopkins University and is a CFA charter-holder and a certified Financial Risk Manager (FRM)."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Alexis-Pappas.png",
            shortname: "Alexis Pappas",
            position: "",
            bio: "The Chief Innovation Officer at energy blockchain and digital finance firm GuildOne, Alexis Pappas has led diverse projects that leverage technologies like smart contracts and tokenization to support the energy transition and trusted climate action. She is a frequent speaker and writer on topics like digital emissions management, IoT, AI and blockchain technology convergence, and blockchain's role in the future of ESG investment. As the Executive Director of the Canadian Blockchain Association for Women and the Chair of the Canadian Blockchain Consortium's ESG Committee, Alexis believes that inclusive change is the key to global sustainability."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Dr-Burcu-Unuvar.png",
            shortname: "Dr Burcu Unuvar",
            position: "",
            bio: "Burcu Unuvar is the Chief Economist and Head of the Economic Research Department at TSKB (Industrial Development Bank of Turkey), assuming this role in April 2017. In this capacity, she oversees macroeconomic research initiatives with a primary focus on markets, developmental matters, and ecosystem crises. Before her tenure at TSKB, Ms. Unuvar held a distinguished academic position as a faculty member within the Department of Economics at Bilkent University. Her academic contributions primarily center around monetary economics, encompassing subjects such as monetary policy, central bank communication, and green central banking. She continues to engage in rigorous research within these domains as a non-teaching academic. Complementing her academic achievements, Ms. Unuvar boasts a decade-long background in investment banking as an economist. Her professional journey is underpinned by a comprehensive educational foundation, including a Bachelor's Degree in Business Administration, an MSc Degree in Applied Economics and Finance, and a distinguished PhD in Economics."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Alexander-Westphal.png",
            shortname: "Alexander Westphal",
            position: "",
            bio: "Alexander is a Director in ICMA's Market Practice and Regulatory Policy team in London, focused primarily on repo and collateral. He serves as secretary to ICMA’s European Repo and Collateral Council (ERCC), the main representative body for the cross-border repo and collateral market in Europe, as well as ICMA’s newly established Global Repo and Collateral Forum (GRCF). In this capacity, Alexander has been following closely the various market and regulatory developments impacting repo and collateral markets in Europe and beyond and has been coordinating related market practice and advocacy efforts. He represents ICMA in various industry and official sector advisory groups, including as a member of ESMA’s Post-Trade Working Group CWG. Alexander joined ICMA in 2015. Prior to this, he worked as Policy Adviser at the European CSD Association (ECSDA) in Brussels, where he coordinated the work of ECSDA’s working group on public policy, and previously at the European Commission (DG ECFIN). Alexander holds a Master’s degree in Economics from the University of Konstanz (Germany) and a Master’s in European Studies from the College of Europe in Bruges (Belgium)."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Devina+Paul.png",
            shortname: "Devina Paul",
            position: "",
            bio: "Devina has over 20 year's experience as a finance professional and has spent the last 10 years working with start-ups to prepare their business for investment, scale and high growth. As Zumo’s CFO, she has oversight of the company’s financial goals, objectives and budgets. Devina is also an early-stage investor and Co-Founder of Galvanise Capital, an angel investment syndicate. She has multiple exits and funding rounds under her belt - most notably with I Spy to Dentsu Aegis, Encore Digital Media to Next 15 and Aquila Insight to Dentsu Aegis. Well known on the business scene, she holds several board NED and advisory roles; is a trustee of Re:Generate and Centre for Youth Impact; and is an advisor to One Tech. Devina is passionate about financial wellbeing and accessibility, and regularly hosts workshops on best investment practices."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Mina+Nasif.png",
            shortname: "Mina Nasif",
            position: "",
            bio: "Mina Nasif, a Certified Treasury Professional, currently serves as the Managing Director of Partnerships MEA at, AFP addition to being Chief Visionary Officer at Beacon FinTrain, he demonstrates exceptional leadership and strategic acumen in International Treasury Management, Corporate Finance, and Derivatives use. Previously, he was The Group Treasury Manager at TAQA Arabia and the Group Treasury Manager at Al-Gihaz Holding. Mina holds certifications such as Certified Professional Trainer, ACI Dealing Certificate, and AMCT Treasury Diploma, and is a Certified Treasury Professional (CTP). With 17 years in International Corporate Treasury Management and 15 years as a CTP Trainer and Treasury Consultant, Mina Nasif excels in the finance and treasury industry, serving several companies in Egypt, the U.K., Algeria, and Saudi Arabia."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Sanna_Clausse_GCCFMT_Speaker.png",
            shortname: "Sanaa Clausse",
            position: "",
            bio: "Sanaa Clausse - BenAbdelhadi – Senior Director, Head of Business Development at the International Capital Market Association (ICMA) Sanaa Clausse – BenAbdelhadi is a Senior Director, Head of Business Development for the International Capital Market Association (ICMA). Sanaa oversees the appointment of new members of the association worldwide, as well as overseeing member retention and promoting ICMA across new markets, particularly MENA and Africa. Sanaa is also engaged with the ICMA’s Women’s Network, where she supervises the initiatives across France & MENA. Prior to joining ICMA, Sanaa worked as an Equity Data Sales Executive at Markit (now S&P Global).  She started her career in 2005 as a Paris-based sales executive for Acxiom, a pioneer and leader in data-driven marketing. Sanaa holds Masters degree in Langues Étrangères Appliquées (Business Management and Languages - English and Spanish), with a major in Corporate Communications from the Université Paris Sorbonne. She also graduated from NEOMA Business School in Reims with a Master Grandes Écoles with a major in Sales, Marketing & Corporate Communications."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Nimet+Sinem+Yahsi.png",
            shortname: "Nimet Sinem Yahsi",
            position: "",
            bio: "Nimet Sinem Yahsi is the Senior Vice President of Structured Finance and Sustainability at DenizBank. She earned her bachelor's degree in Economics from Istanbul University, followed by a master's degree in Capital Markets and Stock Exchange. She started her career as a Management Trainee at OyakBank before joining DenizBank in 2007. With over 20 years of experience in banking, she holds expertise in Correspondent Banking, Debt Capital Markets, Sustainability and Structured Loan Products Origination i.e. Syndicated Loans, Diversified Payment Rights securitization, Covered Bonds, ESG themed/linked loans, Multilateral Loans."
        },
        {
            avatar: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/speakers/Tahani+Abo+Al+Reesh.png",
            shortname: "Tahani Abo Al Reesh",
            position: "",
            bio: "Tahani Abo Al Reesh is a seasoned finance professional with a strong background in corporate treasury, finance trade, taxation, and VAT specialization. With an MBA in Finance and an ongoing pursuit of the Certified Treasury Professional (CTP) designation, Tahani brings a wealth of knowledge and expertise to the financial sector. Currently serving as the Corporate Treasury, Finance Trade & Taxation Manager at Isam Kabbani & Partners for Construction & Maintenance Co. Ltd in Jeddah since February 2022, Tahani has demonstrated her proficiency in managing complex financial matters within the construction and maintenance industry. Prior to her current role, Tahani held the position of Chief Accountant - Taxation at Isam Kabbani & Partners for Construction & Maintenance Co. Ltd from January 2020 to January 2022, where she played a pivotal role in overseeing financial and taxation matters for the company. Her journey in the finance field began in June 2013, when she served as the CFO Assistant at Isam Kabbani & Partners for Construction & Maintenance Co. Ltd. Over the course of seven years in this role, she honed her financial acumen and contributed significantly to the company's financial success. Tahani's career also includes experience at the International Medical Center Hospital in Jeddah, where she served as the CEO Office Coordinator from May 2009 to May 2013 and as a Billing Officer from July 2008 to April 2009. Tahani Abo Al Reesh's dedication to financial excellence, her pursuit of continuous professional development, and her extensive experience make her a trusted expert in finance, taxation, and corporate treasury. She is a valuable asset to any organization fortunate enough to benefit from her expertise."
        }

    ]
};
