import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import {
  Container,
  Section,
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  MediaWrapper,
  Img,
  ContentColumn,
} from '../../../global.style';

export const Overview = (props) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section inverse={props.inverse} ref={ref}>
      <Container>
        <ContentRow reverse={props.reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {props.topLine.text}
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.headline}
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.paragraph1}
              </Subtitle>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.9, duration: 0.6 }}
                animate={animation}
                inverse={props.inverse}
              >
                {props.description.paragraph2}
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <MediaWrapper>
              <Img
                src={props.img}
                alt={props.alt}
                transition={{ duration: 0.5 }}
                style={{ width: "80%" }}
              />
            </MediaWrapper>
          </ContentColumn>
        </ContentRow>
      </Container>
    </Section>
  );
};
