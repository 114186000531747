import React, { useEffect } from "react";
import { Section, Container } from "../../global.style";
import { StyledTabs, TabPanel, TabItem, useStyles } from "./info.elements";

import { Overview } from "./Overview";
import { Committees } from "./Committees";
import { Travel } from "./Travel";
import { Venue } from "./Venue";
import { Accommodation } from "./Accommodation";
import { overview, gcc } from "../../data/overview.data";
import { uaefmaCommittee, gccCommittee } from "../../data/committees.data";
import { travel } from "../../data/travel.data";
import { venue } from "../../data/venue.data";
import { accommodation } from "../../data/accommodation.data";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Accordion } from "../Accordion";
import { Gallery } from "../HomePage/Gallery";
import { gallery } from "../../data/gallery.data";

export default function InformationTabs({ inverse }) {

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const classes = useStyles();
    const [value, setValue] = React.useState(id | 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Section inverse={inverse}>
            <Container>
                <StyledTabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Information Tabs"
                >
                    <TabItem label="Overview" />
                    <TabItem label="Committees" />
                    <TabItem label="Travelling to Turkey" />
                    {/* Show once venue is confirmed */}
                    <TabItem label="Venue" />
                    {/* <TabItem label="Accommodation" /> */}
                </StyledTabs>
                <TabPanel value={value} index={0}>
                    <Overview {...overview} />
                    <Overview {...gcc} />
                    <Gallery {...gallery} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Committees {...uaefmaCommittee} />
                    <Committees {...gccCommittee} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Travel {...travel} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Venue {...venue} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Accommodation {...accommodation} />
                </TabPanel>
            </Container>
        </Section>
    );
}
