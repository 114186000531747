import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export const TabItem = withStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: "center",
        color: "#313886",
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "14px",
        textTransform: "uppercase",
        outline: "none",

        "&:hover": {
            display: "inline-block",
            position: "relative",
            textDecoration: "none",
            color: "#313886",
            opacity: 1,
        },
        "&$selected": {
            color: "##313886",
            fontWeight: 600,
            outline: "none",
        },
        "&:focus": {
            color: "#313886",
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const StyledTabs = withStyles({
    indicator: {
        backgroundColor: "#313886",
        height: "5px",
        borderRadius: "50px",
        transition: "all 0.5s ease",
        width: "100%"
    }
})(Tabs);
export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
}));
