import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { CardSection } from '../../global.style';
import Ticket from '../HomePage/Ticket';
import { ticket } from '../../data/ticket.data';

export const Tickets = () => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <CardSection inverse={!ticket.inverse} ref={ref}>
      <Ticket {...ticket} bgColor="#f4f4f4" />
    </CardSection>
  );
};
