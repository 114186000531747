import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MediaWrapper = styled(motion.div)`
	display: flex;
	justify-content: flex-end;
	max-height: 700px;
	justify-content: center;
	position: relative;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 40%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
`;

export const ButtonWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
`