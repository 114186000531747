import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Heading = styled(motion.div)`
	color: #313886;
    font-size: 36px;
    font-weight: 600;
    /* text-align: center; */
    /* color: ${({ lightText }) => (lightText ? '##f1f8fa' : '#010606')}; */

    /* @media screen and (max-width: 768px) {
        font-size: 32px;
    } */
`

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	justify-content: space-around;

	/* @media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	} */
`;

export const ContentColumn = styled(motion.div)`
	flex: 1;
	display: flex;
	flex-direction: column;

	/* @media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
		justify-content: center;
		align-items: center;
	} */
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;

	/* @media screen and (max-width: 768px) {
		> h1,
		p {
			text-align: center;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	> img {
		width: 300px;
		//margin-left: -3px;
	} */
`;

export const MediaWrapper = styled(motion.div)`
	display: flex;
	justify-content: 'flex-end';
	justify-content: center;
	position: relative;
`;

export const TopLine = styled(motion.div)`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
	//margin-bottom: 16px;
	//margin-top: 3rem;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
`;


export const CardContent = styled(motion.div)`
	display: grid;
    width: 100%;
	grid-template-columns: repeat(5, 1fr);
  
	/* @media screen and (max-width: 1400px) {
  		grid-template-columns: repeat(3, 1fr);
  	}
  	@media screen and (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 768px) {
  		grid-template-columns: repeat(1, 1fr);
  	} */

/* @media screen and (max-width: 1400px) {
	padding: 0 50px;
}
@media screen and (max-width: 1200px) {
	padding: 0 50px;
}
@media screen and (max-width: 992px) {
	height: 500px;
}
@media screen and (max-width: 768px) {
	height: 200px;
} */
`