
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from '../../utils/useMediaBreakpoints';

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	justify-content: space-around;
`;

export const ContentColumn = styled.div`
	margin-bottom: 15px;
	padding-right: 15px;
	padding-left: 15px;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
`;

export const MediaWrapper = styled.div`
	display: flex;
	justify-content: center;
	max-height: 700px;
	position: relative;
`;

export const TopLine = styled.div`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
    text-align: center;
	padding-bottom: 5px;
`;

export const BottomLine = styled.div`
	color: #F4F4F4;
	font-size: 12px;
	letter-spacing: 3px;
    text-align: center;
`;

export const Img = styled.img`
	max-width: 80%;
	justify-content: center
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 162px;
    box-sizing: border-box;
    color: #F4F4F4;
`;

export const SocialMediaWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FooterLink = styled(Link)`
    color: #F4F4F4;
    text-decoration: none;
    font-size: 14px;
    &:hover {
        font-weight: 600;
        text-decoration: none;
        color: #01bf71;
    }
`

export const SocialIconLink = styled.a`
    color: #F4F4F4;
    font-size: 24px;
	padding: 1rem;

    &:hover {
        color: #01bf71;
    }
`
export const FooterContact = styled(Link)`
    color: #F4F4F4;
    text-decoration: none;
    font-size: 14px;
	&:hover {
        text-decoration: none;
        color: #01bf71;
    }
`