export const gallery = {
    reverse: false,
    inverse: true,
    topLine: "Through the years",
    headline: "Conferences by UAEFMA",
    data: [
        {
            title: "3rd GCCFMT Conference",
            embed: "i1CVW2yoQuw"
        },
        {
            title: "ACICA 2021 : Disruptive Impact of AI",
            embed: "oiHD3Rxr0yc"
        },
        {
            title: "ACICA 2021 : Opening Speech",
            embed: "1t5Z_V9QZGg"
        }
    ]
};
