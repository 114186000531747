import React from "react";
import HeroImage from "../components/Hero/hero.image";
import { agenda } from "../data/hero.data";
import AgendaTabs from "../components/Agenda";
import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";

const AgendaContent = () => {
  const metaData = getMetaData("agenda");

  return (
    <>
      <HeroImage {...agenda} style={{ width: "10px" }} />
      <MetaTags metaData={metaData} />
      <AgendaTabs inverse={true} />
    </>
  )
}

export default AgendaContent