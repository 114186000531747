import React from "react";
import Helmet from "react-helmet";

const getDefault = {
  title: "ACICA",
  description: "ACICA",
  keywords: "ACICA",
  robots: "",
  canonicalUrl: "https://www.acicadubai.com",
};

export const getPageMetadata = (metaData) => {
  const { title, description, keywords, robots, canonicalUrl } = metaData;
  const defaultData = getDefault;
  return {
    title: title || defaultData?.title,
    description: description || defaultData?.description,
    keywords: keywords || defaultData?.keywords,
    robots: canonicalUrl || defaultData?.canonicalUrl,
    canonicalUrl: canonicalUrl || defaultData?.canonicalUrl,
  };
};

const MetaTags = (props) => {
  const { metaData } = props;
  const meta = getPageMetadata(metaData);
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      {meta.robots && <meta name="robots" content={meta.robots} />}
      <link rel="canonical" href={meta.canonicalUrl} />
    </Helmet>
  );
};

export default MetaTags;
