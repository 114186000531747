import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Heading = styled(motion.div)`
	color: #313886;
    font-size: 36px;
    font-weight: 600;
`

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	justify-content: space-around;
`;

export const ContentColumn = styled(motion.div)`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
`;

export const MediaWrapper = styled(motion.div)`
	display: flex;
	justify-content: 'flex-end';
	justify-content: center;
	position: relative;
`;

export const TopLine = styled(motion.div)`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
`;


export const CardContent = styled(motion.div)`
	display: grid;
    width: 100%;
	grid-template-columns: repeat(5, 1fr);
`