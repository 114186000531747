import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from '../../utils/useMediaBreakpoints';

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	width: 72%;
	justify-content: space-around;
`;

export const ContentColumn = styled(motion.div)`
	display: flex;
	flex-direction: column;
`;

export const TextWrapper = styled.div`
	padding-top: 0;
`;

export const MediaWrapper = styled(motion.div)`
	display: flex;
	justify-content: 'flex-end';
	max-height: 700px;
	justify-content: center;
	position: relative;
`;

export const TopLine = styled(motion.div)`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
`;

export const Heading = styled(motion.h2)`
	font-size: 3rem;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ inverse }) => (inverse ? '#313886' : '#f4f4f4')};
	${breakpoints("font-size", "rem", [{ 500: 2 }])}
`;

export const ScheduleWrapper = styled(motion.div)`
   display: grid;
   grid-template-columns:  repeat(2, 150px);
	${breakpoints("grid-template-columns", "", [{ 500: "repeat(2, 120px);" }])}
`;

export const Time = styled(motion.p)`
	text-align: left;
	line-height: 20px;
	font-weight: 900;
	color: "#6a6a6a";
	${breakpoints("font-size", "px", [{ 500: 14 }])}
`;

export const Title = styled(motion.p)`
	line-height: 20px;
	color: "#6a6a6a";
	font-weight: 600;
	width: 750px;

	${breakpoints("width", "%", [{ 500: 160 }])}
	${breakpoints("font-size", "px", [{ 500: 14 }])}
`;

export const DescriptionWrapper = styled(motion.div)`
	font-size: 16px;
	width: 500%;
	margin-left: 9.32rem;
	text-align: justify;

	${breakpoints("margin-left", "rem", [{ 500: 0 }])}
	${breakpoints("width", "px", [{ 500: 280 }])}
`

export const DiscussionTitle = styled(motion.p)`
	/* line-height: 10px; */
	color: "#6a6a6a";
	${breakpoints("font-size", "px", [{ 500: 14 }])}
	${breakpoints("width", "px", [{ 967: 610 }])}
	${breakpoints("width", "px", [{ 768: 510 }])}
	${breakpoints("width", "px", [{ 500: 310 }])}
`;

export const Description = styled(motion.p)`
	/* line-height: 10px; */
	color: "#6a6a6a";
	white-space: pre-wrap;
	margin-left: 30px;
	${breakpoints("font-size", "px", [{ 500: 12 }])}
`;

export const ContentButton = styled(motion.button)`
	border-radius: 10px;
    background: ${({ primary }) => (primary ? '#313886' : '#F4F4F4')};
    white-space: nowrap;
    padding: 10px 35px;
    color: #2B2B2B;
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '#16px')};
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    padding-top: 13px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #313886;
        color: #F4F4F4;
        text-decoration: none;
    }
`;
