import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { breakpoints } from "../../utils/useMediaBreakpoints";

export const Nav = styled.nav`
    background: ${({ scroll }) => (scroll ? '#313886' : 'transparent')};
    height: 80px;
    margin-top: -120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0px;
    z-index: 10;

`

export const NavBarContainer = styled.div`
    display: flex;
    height: 120px;
`

export const NavLogo = styled(LinkRouter)`
    justify-self: flex-start;
    display: flex;
    align-items: center;
    
    cursor: pointer;

    img {
        width: 175px;
    }

`

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase; 
    letter-spacing: 3px;   
    padding: 0 10rem;
    
    cursor: pointer;

    ${breakpoints("display", "", [{ 1200: "none" }])}
    ${breakpoints("padding", "", [{ 1500: "0 5rem" }, { 1400: "0 4rem" }, { 1300: "0 3rem" }])}
`

export const NavItem = styled.li`
    height: 3px;
`

export const NavLinks = styled(LinkRouter)`
    color: #f4f4f4;
    display: flex;
    text-decoration: none;
    flex-direction: row;
    height: 10%;
    width: 100%;
    padding: 0 3rem;
    justify-content: center;
    align-content: center;

    cursor: pointer;

    &:hover {
        display: inline-block;
        position: relative;
        padding-bottom: 3px;
        text-decoration: none;
        color: #f4f4f4;
    }

    &:after {
        content: '';
        display: block;
        margin: 8px auto;
        height: 5px;
        width: 0px;
        background: transparent;
        transition: width 0.5s ease, background-color 0.2s ease;
        border-radius: 50px;
    }

    &:hover:after {
        width: 50%;
        background: #f4f4f4;
    }
    
    ${breakpoints("padding", "", [{ 1400: "0 2rem" }])}
`
export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 1200px) {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        font-size: 1.8rem;
        color: #f4f4f4;
        cursor: pointer;
    }
`

export const NavButton = styled.nav`
    display: flex;
    align-items: center;
    /* position: relative;
    top:-35%; */

    @media screen and (max-width: 1200px) {
        display: none;
    }
`

export const NavButtonLink = styled(LinkRouter)`
    border-radius: 10px;
    background: #f4f4f4;
    white-space: nowrap;
    padding: 10px 35px;
    color: #2B2B2B;
    font-size: 14px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    padding-top: 13px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2B2B2B;
        color: #f4f4f4;
        text-decoration: none;
    }
`