import React, { useState } from 'react';
import { CardContainer, Section, TopLine, Heading, CardSection } from '../../../global.style';
import { Modal } from 'react-bootstrap';
import {
    Content,
    Box,
    Avatar,
    AvatarIcon,
    AvatarWrapper,
    AvatarInfo,
    AvatarName,
    AvatarPosition,

} from './speakers.elements';
import styled from 'styled-components';

const modalContentStyle = {
    backgroundColor: '#090e4a',
    color: 'white',
    textAlign: 'justify',
};

const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 576px) {
        flex-direction: row;
        align-items: flex-start;
        
    }
`;

const StyledModal = styled(Modal)`
    & .modal-dialog {
        max-width: 500px;
        width: 90%;
        margin: 1.75rem auto;
    }

    @media screen and (min-width: 768px) {
        & .modal-dialog {
            max-width: 768px !important;
        }

        & img {
        width: 30% !important;
        margin-bottom: 12px;

        }
    }

    @media screen and (max-width: 500px) {
        & .modal-dialog {
            max-width: 500px !important;
        }

        & img {
        width: 100% !important;
        margin-bottom: 12px;

        }

    }    

    & .close {
        color: white;
    }

    & .close:hover,
    & .close:focus {
        color: white;
    }
`;

export const Speakers = (props) => {
    const [show, setShow] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);

    const handleShowModal = (speaker) => {
        setSelectedSpeaker(speaker);
        setShow(true);
    };

    const handleCloseModal = () => {
        setShow(false);
    };

    return (
        <>
            <CardSection inverse={props.inverse}>
                <CardContainer>
                    <TopLine>{props.topLine}</TopLine>
                    <Heading inverse={props.inverse}>{props.headline}</Heading>
                    <Content>
                        {props.data.map((s) => (
                            <Box key={s.shortname} onClick={() => handleShowModal(s)}>
                                <Avatar>
                                    {s.width ? (
                                        <AvatarIcon
                                            src={s.avatar}
                                            alt=""
                                            style={{ width: '50%', height: '50%', margin: '80px 62px' }}
                                        />
                                    ) : (
                                        <AvatarIcon src={s.avatar} alt="" />
                                    )}
                                </Avatar>
                                <AvatarWrapper>
                                    <AvatarInfo>
                                        <AvatarName>{s.shortname}</AvatarName>
                                        <AvatarPosition>{s.position}</AvatarPosition>
                                    </AvatarInfo>
                                </AvatarWrapper>
                            </Box>
                        ))}
                    </Content>
                </CardContainer>
            </CardSection>
            {selectedSpeaker && (
                <StyledModal
                    show={show}
                    onHide={handleCloseModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton style={modalContentStyle}>
                        <Modal.Title id="example-custom-modal-styling-title" style={{ fontWeight: 'bold' }}>
                            {selectedSpeaker.shortname}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={modalContentStyle}>
                        <ModalBodyWrapper>
                            <img
                                src={selectedSpeaker.avatar}
                                alt={selectedSpeaker.shortname}
                                style={{ width: '30%', marginRight: '16px' }}
                            />
                            <p style={{ wordBreak: 'break-word' }}>{selectedSpeaker.bio}</p>
                        </ModalBodyWrapper>
                    </Modal.Body>
                </StyledModal>
            )}
        </>
    );
};
