import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    color: #313886;

    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    text-align: center;
    color: #242424 !important;
    /* letter-spacing: 3px; */
    /* text-transform: uppercase; */
    font-size: 18px;
`

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

export const Card = styled(Link)`
  background: #f4f4f4;
  box-shadow: 0 6px 20px rgba(11, 28, 58, 0.2);
  width: 280px;
  height: 440px;
  text-decoration: none;
  border-radius: 10px;
  margin: 16px;

  &:nth-child(3) {
    margin: 16px;
  }


  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
      text-decoration: none;
      color: #2b2b2b;
    }
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
  color: #F4F4F4;
  padding-bottom:40px;
`
export const CardPlanMain = styled.div`
  line-height: 1.5
`

export const CardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #2B2B2B;

`


export const CardCost = styled.h4`
  font-size: 70px;
`

export const CardLength = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #2B2B2B;
`
export const ContentButton = styled.div`
display: flex;
flex-direction: column;
padding: 12px;
align-items: center;
color: #F4F4F4;
padding-bottom:40px;
`



