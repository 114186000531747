import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { CardContainer, CardSection } from '../../../global.style';
import { Speakers as SpeakersCard } from '../../HomePage/Speakers';

import {
  TopLine,
  Heading,
  ContentRow,
  TextWrapper,
  ContentColumn,
  CardContent
} from './committees.elements';

export const Committees = (props) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <CardSection inverse={props.inverse} ref={ref}>
      <SpeakersCard {...props} />
    </CardSection>
  );
};
