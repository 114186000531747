export const ticket = {
    inverse: true,
    topLine: "Delegate Fees",
    headline: "Register now to secure your seats to this year's conference",
    closeheadline: "REGISTRATION CLOSED",
    buttonLabelOne: "Card Payment",
    buttonLabelTwo: "Pay by Bank Transfer",
    primary: true,
    secondary: false,
    data: [
        {
            title: "International Delegates",
            subtitle: "",
            cost: "950",
            inclusions: ["3-Day access", "After-event Party", "E-Certificate"],
            currency: "USD",
            discounted: false,
            discountCaption: "(Early Bird Promo)",
            discount: "799",
            croute: "https://buy.stripe.com/8wMdSOdOC4pZaiYbIL",
            broute: "https://forms.gle/bHsFYHWzaBozUQKU8",
            qrcode: require("../assets/qr/stripe-regular.png")
        },
        {
            title: "UAEFMA Members",
            subtitle: "",
            cost: "600",
            inclusions: ["3-Day access", "After-event Party", "E-Certificate"],
            currency: "USD",
            discounted: false,
            discountCaption: "(Early Bird Promo)",
            discount: "599",
            croute: "https://buy.stripe.com/14k01Y7qecWv8aQ004",
            broute: "https://forms.gle/siGPp2rnp1zWFdCF6",
            qrcode: require("../assets/qr/stripe-members.png")
        },
        {
            title: "Local Delegates",
            subtitle: "From Turkey",
            cost: "600",
            inclusions: ["3-Day access", "After-event Party", "E-Certificate"],
            currency: "USD",
            discounted: false,
            discountCaption: "(Early Bird Promo)",
            discount: "499",
            croute: "https://buy.stripe.com/5kA7uq5i67Cbeze4gl",
            broute: "https://forms.gle/HVmCDNoW9mqK2i8h8",
            qrcode: require("../assets/qr/stripe-local.png")
        }
    ]
};
