import styled from "styled-components";

export const AccordionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f4f4f4;
`

export const Container = styled.div`
    width: 100%;
    top: 22%;
    position: absolute;
    box-shadow: 2px 10px 35px 1px rgba(153,153,153,0.3);
`

export const Wrap = styled.div`
    background: #313886;
    color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;

    h1 {
        padding: 1rem;
        font-size: 1rem;
    }

    span {
        margin-right: 1.5rem;
    }
`

export const Dropdown = styled.div`
    background: #f4f4f4;
    color: #2b2b2b;
    margin: 25px;
    width: 100%;
`