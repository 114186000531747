import React from 'react';
import { CardContainer, Section, TopLine, Heading, CardSection } from '../../global.style';


import { Content } from './tutorial.elements';

export const Tutorial = (props) => {
    return (
        <>
            <CardSection inverse={props.inverse} style={{ alignItems: "center" }}>
                <CardContainer>
                    {/* <TopLine>{props.topLine}</TopLine> */}
                    {/* <Heading inverse={props.inverse}>{props.headline}</Heading> */}
                    <Content>
                        {props.data.map((s, index) => (
                            <div key={index}>
                                <video style={{ width: '72vw', height: '36vw' }} alt={s.alt} controls poster={s.thumbnail} >
                                    <source src={s.videoLink} type="video/mp4" />
                                </video>
                            </div>
                        ))}
                    </Content>
                </CardContainer>
            </CardSection>
        </>
    );
}
