import React from "react";
import { Link } from "react-router-dom";
import { BrowserView, isMobile } from "react-device-detect";

// components
import Form from "../components/FormRegistration";
// import RegisterTopBar from "../components/RegisterTopBar";

import MetaTags from "../shared/MetaTags";
import getMetaData from "../shared/getMetaData";

// local assets
// import logo from "../assets/colored-logo.png";
import header from "../assets/img/gcc-white.png";
import { Section, Container, TextWrapper } from "../global.style";
import { HeroH1 } from "../components/Hero/hero.elements";

const Register = () => {
  const metaData = getMetaData("agenda");

  return (
    <Section>
      <Container>
        <HeroH1 style={{ padding: "120px 80px", textAlign: "center" }}>Coming Soon</HeroH1>
      </Container>
    </Section>
    // <div style={{ display: "block", height: "100vh" }}>
    //   <MetaTags metaData={metaData} />

    //   <div style={{ display: "flex" }}>
    //     {/* <RegisterTopBar /> */}
    //     {!isMobile ? (
    //       <div
    //         className="de-video-container"
    //         style={{
    //           maxWidth: "50%",
    //           height: "100vh",
    //         }}
    //       >
    //         <div className="de-video-content">
    //           {/* <Link to="/">
    //             <img width="250" src={logo} />
    //           </Link> */}
    //           <div className="video-title">
    //             <img src={header} />
    //           </div>
    //         </div>
    //         <video preload="true" autoPlay loop={true} muted={true} poster="">
    //           <source
    //             src="https://s3.eu-west-3.amazonaws.com/acicadubai.com/vid/dubai-2.mp4"
    //             type="video/mp4"
    //           />
    //         </video>
    //       </div>
    //     ) : (
    //       <></>
    //     )}

    //     <div className="form-responsive">
    //       <div className="col-md-12 text-center ">
    //         <Form />
    //         {/* <p style={{ textAlign: "center", fontSize: "4rem", paddingTop: "5rem", fontWeight: "900" }}>Registration is now officially closed.</p> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Register;
