import { breakpoints } from '../../utils/useMediaBreakpoints';
import styled, { keyframes } from 'styled-components';

export const Content = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  /* grid-template-columns: repeat(1, 1fr); */
  
	${breakpoints("grid-template-columns", "", [{ 800: "repeat(1, 1fr)" }, { 300: "repeat(1, 1fr)" }])}
`

export const Box = styled.div`
  position: relative;
  width: 15rem;
  height: 20rem;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }

  ${breakpoints("width", "rem", [{ 1400: 10 }, { 500: 12 }, { 400: 10 }])}
  ${breakpoints("height", "rem", [{ 1400: 15 }, { 500: 16 }, { 400: 15 }])}
`

export const Avatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 1s ease-in-out;

  &:hover {
    transform: translate(0rem, -8rem);
    cursor: pointer;
  }
`

export const AvatarIcon = styled.img`
  width: 15rem;
  height: 20rem;
  object-fit: cover;
  resize: both;

  &:hover {
    border-bottom: 10px solid;
    border-image: linear-gradient(90deg, purple, #313886) 1;
  }

  ${breakpoints("width", "rem", [{ 1400: 10 }, { 1000: 12 }, { 500: 12 }, { 400: 10 }])}
  ${breakpoints("height", "rem", [{ 1400: 15 }, { 1000: 17 }, { 500: 16 }, { 400: 15 }])}
`

export const AvatarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  background-color: #2b2b2b;
  z-index: 1;
  align-items: flex-end;
  transition: 0.5s ease-in-out;
  justify-content: center;


  ${breakpoints("padding", "rem", [{ 1400: 1 }, { 500: 2 }])}
`

export const AvatarInfo = styled.div`
  text-align: center;

`
export const VideoContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Adjust the height as needed */
`

export const AvatarName = styled.h2`
  font-size: 1rem;
  color: #f4f4f4;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const AvatarPosition = styled.h3`
  color: #c0c0c0;
  font-size: 0.8rem;
  font-weight: 300;
`

export const ImageWrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`

const scrolling = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translatex(-144vw); }
`

export const Image = styled.img`
  flex: 0 0 16vw;
  margin: 0 0;
  display: block;
  width: 20%;
  opacity: 50%;
  filter: grayscale(100%);
  animation-name: ${scrolling};
  animation-duration: 70s;
  animation-iteration-count: infinite;
`