export const hero = {
    id: "hero",
    description: "Be the voice of the new generation",
    headline: "Turkey In Focus",
    buttonLabel: "Learn More",
    img: "https://s3.eu-west-3.amazonaws.com/www.gccfmt.com/videos/hero.mp4",
    logo: require("../assets/img/gcc-dates-for-banner.png"),
    // logo: require("../assets/img/gcc-event-date.png"),
    alt: "Turkey"
};

export const information = {
    id: "information",
    headline: "Information",
    img: require("../assets/img/information-hero.png"),
    alt: "Information"
};

export const agenda = {
    id: "agenda",
    headline: "Agenda",
    img: require("../assets/img/agenda-hero.png"),
    alt: "Agenda"
};

export const speakers = {
    id: "speakers",
    headline: "Speakers",
    img: require("../assets/img/speakers-hero.png"),
    alt: "Speakers"
};

export const tickets = {
    id: "tickets",
    headline: "Delegate Fees",
    img: require("../assets/img/tickets-hero.png"),
    alt: "Delegate Fees"
};