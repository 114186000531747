export const overview = {
    reverse: false,
    inverse: true,
    topLine: {
        text: "Who We Are",
    },
    headline: "About UAEFMA",
    description: {
        paragraph1: "The UAE Financial Market Association was decreed by the Ministry of Social Affairs in December 2011 and is the standard bearer for financial markets professionals in the United Arab Emirates; we have more than 200 members under the umbrella of the UAE FMA.",
        paragraph2: "The UAE FMA is affiliated to ACI – The Financial Markets Association, the global umbrella body of national associations. The Paris-based body has more than 13,000 members worldwide in more than 60 countries. This makes ACI the largest international association in the wholesale financial markets. ACI – The Financial Markets Association was founded in France in 1955 following an agreement between foreign exchange dealers in Paris and London. UAE Financial market Association seeks to add value to the industry and its members by promoting continuous education and social awareness.",
        paragraph3: "The main priorities of the United Arab Emirates Financial Markets Association are to keep members informed of the changes in the financial industry and provide a forum for discussion in organized events on issues affecting the markets, the UAE FMA enhances educational value and working experience and knowledge by providing appropriate educational services to those with a legitimate interest in the financial markets.",
        paragraph4: "UAE FMA also cultivate greater communication channels among members, markets, and other similar bodies so as to give members the opportunity to network through various events organized by the Association.",

    },
    img: require("../assets/logo/uaefma.png"),
    start: 'true',
};

export const gcc = {
    reverse: true,
    inverse: true,
    topLine: {
        text: "What We Do",
    },
    headline: "The 5th GCC FMT Conference",
    description: {
        paragraph1: "The 5th GCC Financial Markets & Treasury Conference will be hosted by the UAE Financial Markets Association, one of the premier Financial Associations in the region, which will provide a gateway for market professionals to take part at the event to gain succinct information from experienced professionals & market-makers on issues affecting the current financial climate in the region and globally.",
        paragraph2: "The event will provide a unique opportunity for delegates to create greater communication channels amongst the numerous market professionals and financial institutes that will be attending as the list has grown over the last year with more diverse institutions and representatives attending.",

    },
    img: require("../assets/img/gcc-updated-blue-logo.png"),
    start: 'true',
};