import styled from "styled-components";
import { breakpoints } from "../../utils/useMediaBreakpoints";

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(
            180deg, 
            #313886 0%,
            rgba(0,0,0,0.6) 100%
        ),
        linear-gradient(
            180deg, 
            rgba(0,0,0,0.2) 0%,
            transparent 100%
        );
    }

    ${breakpoints("height", "px", [{ 800: 700 }, { 500: 500 }])}
`
export const HeroImageContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    padding: 0 100px;
    height: 500px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(
            180deg, 
            #313886 0%,
            rgba(0,0,0,0.6) 100%
        ),
        linear-gradient(
            180deg, 
            rgba(0,0,0,0.2) 0%,
            transparent 100%
        );
    }

    ${breakpoints("height", "px", [{ 1400: 400 }, { 1000: 300 }, { 800: 200 }])}
`


export const HeroBackground = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const HeroImageBackground = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

`

export const VideoBackground = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const ImageBackground = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    width: 70%;

    ${breakpoints("display", "", [{ 1200: "flex" }])}
    ${breakpoints("flex-direction", "", [{ 1200: "column" }])}
    ${breakpoints("grid-gap", "rem", [{ 1200: 0 }])}
`

export const HeroImageContent = styled.div`
    z-index: 3;
    display: flex;
    text-align: left;
    align-items: left;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 5%;

    ${breakpoints("text-align", "", [{ 800: "center" }])}
    ${breakpoints("align-items", "", [{ 800: "center" }])}
    ${breakpoints("margin", "", [{ 800: "25% auto" }, { 600: "40% auto" }, { 485: "50% auto" }, { 350: "85% auto" }])}
`

export const HeroH1 = styled.h1`
    color: #F4F4F4;
    font-size: 130px;
    font-weight: bolder;
    text-align: left;
    justify-content: center;
    align-items: center;
    letter-spacing: 10px;
    padding-top: 10%;
    font-family: 'Staatliches', cursive;

    ${breakpoints("font-size", "px", [{ 1400: 100 }, { 800: 70 }, { 600: 50 }])}
    ${breakpoints("text-align", "", [{ 1200: "center" }])}
`

export const HeroH2 = styled.h2`
    color: #F4F4F4;
    font-size: 70px;
    font-weight: bolder;
    padding-top: 10%;

    ${breakpoints("font-size", "px", [{ 1000: 50 }, { 800: 40 }, { 400: 30 }])}
`

export const HeroLine = styled.div`
    content: '';
    display: block;
    width: 50%;
    background: #f4f4f4;
    border-top: 10px solid #f4f4f4;
    border-radius: 50px;

    ${breakpoints("border-top", "", [{ 600: "5px solid #f4f4f4" }, { 300: "3px solid #f4f4f4" }])}
`

export const HeroParagraph = styled.p`
    color: #F4F4F4;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 7px;
    width: 200%;

    ${breakpoints("letter-spacing", "px", [{ 600: 6 }, { 500: 4 }, { 400: 2 }])}
    ${breakpoints("font-size", "px", [{ 500: 12 }])}
`

export const HeroButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const MediaWrapper = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
`;

export const Img = styled.img`
	border: 0;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	z-index: 1;

    ${breakpoints("width", "%", [{ 700: 130 }])}
`;