export const dayThree = {
    reverse: false,
    inverse: true,
    topLine: "Day 3",
    headline: "15 Sep 2023, Friday",
    schedule: [
        ["09:30 - 10:00", "Networking Exhibition"],
        // ["10:00 - 10:50", "Panel IV – Regulation panel by ICMA – International Capital Markets Association – TBC"],
        ["10:00 - 10:50", "Panel IV - Digital wealth management and related platforms are gradually entering the financial mainstream and becoming a new trend in the banking industry. But are fund managers ready to jump into DeFi? And how do they find ways to monetize investor interest when the price of a major cryptocurrency like Bitcoin plummets?", " ", "• What does digitisation of financial markets actually mean? \n• What are the key challenges and opportunities brought about by the digitalization of financial markets for asset managers, and how does financial education contribute to addressing these challenges and leveraging the opportunities? \n• How can digitalization be used to attract new investors in financial markets and why did Crypto attract so much interest globally including people who have never traded in financial markets before?\n• Are asset managers looking to get into and actually using DeFi? What are the example use cases for this? \n• How will regulation influence the digital assets industry and how can asset managers adapt to this? \n• As we look into the future, what are the key themes that will shape the future the asset management industry?"],
        ["10:50 - 11:20", "Keynote Speaker - Beyond Crypto - Digitisation of Real World Assets - Opportunities for Investors Fund Managers - by Tom James"],
        ["11:20 - 11:50", "Coffee Break and Networking"],
        // ["11:50 - 12:40", "Panel V – Panel by International Securities Lending Association"],
        ["11:50 - 12:40", "Panel V - Renewable energy and the future of carbon credit trading", "Understanding the role of trading platforms | how these service a diverse set of both compliance and voluntary markets | What makes for an efficient registry service and what innovation can we expect to see in the green energy financing space.", "• Will this new market be OTC or Exchange Traded? \n• Will the physical supply side be digitised as a Token Asset, and how will this affect supply? \n• What is the role of existing registries if assets are tokenised and placed with digital custody providers? \n• How should verification/rating/benchmark providers support the market?\n• How does the market evolve beyond Spot?\n• What do Derivatives look like, and what is the role of ISDA?\n• How can I hedge trade finance vs emissions offsets? What will carbon marketplaces look like worldwide if we have 180 nations wanting to be sell-side but protect the sovereign rights of carbon sinks?\n• What eco-systems need to exist to connect supply with demand?\n• How can we ensure no double spending of credits?\n• What innovation financing models will emerge for green energy financing?\n• What will the role of collateralisation and treasury be in carbon/ green financing?\n• What will be the key themes for COP28 in Dubai relating to renewable energy?"],
        ["12:40 - 14:00", "Lunch"],
        ["15:00", "End"],
    ],
};