import React, { useState, useEffect } from 'react'
import useWindowDimensions from '../../utils/useWindowDimensions';
import { FaBars } from 'react-icons/fa';
import { ArrowForward, ArrowRight } from '../../global.style';
import {
    Nav,
    NavBarContainer,
    NavLogo,
    NavMenu,
    NavItem,
    NavLinks,
    NavButton,
    NavButtonLink,
    MobileIcon
} from './navbar.elements';
import logo from '../../assets/img/gcc-white-small.png';

export const NavBar = ({ toggle }) => {
    const { height, width } = useWindowDimensions();
    const [scroll, setScroll] = useState(false);

    const onScroll = () => {
        if (window.scrollY >= 80) {
            setScroll(true);
        }
        else {
            setScroll(false);
        }
    }

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }


    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    });

    return (
        <>
            <Nav scroll={scroll}>
                <NavBarContainer>
                    <NavLogo to='/'><img src={logo} /></NavLogo>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='/'>Home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='information'>Information</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='agenda'>Agenda</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='speakers'>Speakers</NavLinks>
                        </NavItem>
                        {/* <NavItem>
                            <NavLinks to='tickets'>Fees</NavLinks>
                        </NavItem> */}
                    </NavMenu>
                    {/* {width > 500 ? <NavLogo to='/'><img src={logo} /></NavLogo> : <></>} */}
                    <NavButton>
                        <NavButtonLink to='tickets' onMouseEnter={onHover} onMouseLeave={onHover}>
                            Register Now {hover ? <ArrowForward /> : <ArrowRight />} </NavButtonLink>
                    </NavButton>

                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                </NavBarContainer>
            </Nav>
        </>
    )
}