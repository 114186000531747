import React from "react";
import { Container, Section } from '../../global.style';

import {
    Content,
    Avatar,
    AvatarInfo,
    AvatarName
} from './cards.elements';

const Cards = ({ speaker }) => {
    return (
        <Section inverse={true}>
            <Container>
                <Content>
                    <Avatar src="../../../../assets/img/gcc-white.png">
                        <AvatarInfo>
                            <AvatarName>{speaker.title}</AvatarName>
                        </AvatarInfo>
                    </Avatar>
                </Content>
            </Container>
        </Section>
    );
};

export default Cards;
