import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import css
import "../node_modules/video-react/dist/video-react.css";

// pages
import Home from "./pages/Home";
import Register from "./pages/Register"
import Information from "./pages/Information"
import Agenda from "./pages/Agenda"
import Speakers from "./pages/Speakers";
import Tickets from "./pages/Tickets";
// import Bio from "./pages/Bio";
import { Footer } from "./layout/Footer";
import { NavBar } from "./layout/NavBar";
import { SideBar } from "./layout/SideBar";
import { footer } from "./data/footer.data";

export default function App() {
  useEffect(() => {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6110c8cdd6e7610a49af40eb/1fckp0pim';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    return () => {

    }
  }, [])

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }


  return (
    <Router>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />

      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/register" component={Register} exact />
        <Route path="/information" component={Information} exact />
        <Route path="/agenda" component={Agenda} exact />
        <Route path="/speakers" component={Speakers} exact />
        <Route path="/tickets" component={Tickets} exact />
        {/* <Route path="/bio" component={Bio} exact /> */}
      </Switch>

      <Footer {...footer} />
    </Router>
  );
}
