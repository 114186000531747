import { breakpoints } from '../../../utils/useMediaBreakpoints';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);

  ${breakpoints("grid-template-columns", "", [{ 1300: "repeat(4, 1fr)" }, { 1200: "repeat(3, 1fr)" }, { 800: "repeat(2, 1fr)" }, { 300: "repeat(1, 1fr)" }])}
`

export const Box = styled(Link)`
  position: relative;
  width: 15rem;
  height: 20rem;
  padding: 10px;

  &:hover {
    cursor: pointer;
    border-bottom: 10px solid;
    border-image: linear-gradient(90deg, purple, #313886) 1;
  }

  ${breakpoints("width", "rem", [{ 1400: 10 }, { 1000: 12 }, { 500: 12 }, { 400: 10 }])}
  ${breakpoints("height", "rem", [{ 1400: 15 }, { 1000: 17 }, { 500: 16 }, { 400: 15 }])}
`

export const Avatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  opacity: 1;

  &:hover {
    opacity: 0.05;
    cursor: pointer;
  }
`

export const AvatarIcon = styled.img`
  width: 15rem;
  height: 20rem;
  object-fit: cover;
  resize: both;

  &:hover {
  }

  ${breakpoints("width", "rem", [{ 1400: 10 }, { 1000: 12 }, { 500: 12 }, { 400: 10 }])}
  ${breakpoints("height", "rem", [{ 1400: 15 }, { 1000: 17 }, { 500: 16 }, { 400: 15 }])}
`

export const AvatarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  background-color: #2b2b2b;
  z-index: 1;
  align-items: flex-end;
  transition: 0.5s ease-in-out;
  justify-content: center;


  ${breakpoints("padding", "rem", [{ 1400: 1 }, { 500: 2 }])}
`

export const AvatarInfo = styled.div`
  text-align: center;

`

export const AvatarName = styled.h2`
  font-size: 1rem;
  color: #f4f4f4;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const AvatarPosition = styled.h3`
  color: #c0c0c0;
  font-size: 0.8rem;
  font-weight: 300;
`
