import React, { useState } from "react";
import {
  Container,
  ContentButton,
  Img,
  MediaWrapper,
  Section,
} from "../../../global.style";

import {
  Heading,
  Subtitle,
  RegistrationClose,
  CardContent,
  Card,
  CardInfo,
  CardPlan,
  CardCost,
  CardInclusions,
  CardInclusion,
  CardLength,
  Currency,
  Discount,
} from "./ticket.elements";
import img from "../../../assets/dont-miss-hero.png";
import { FaCheck } from "react-icons/fa";

export const Ticket = (props) => {

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const [flipped, setFlipped] = useState(false);

  const styles = {
    card: {
      transformStyle: "preserve-3d",
      transition: "all 0.5s ease",
      transform: !flipped ? "rotateY(0deg)" : "rotateY(180deg)",
    },
    front: {
      height: "100%",
      width: "100%",
      backfaceVisibility: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    back: {
      height: "100%",
      width: "100%",
      backfaceVisibility: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "#242424",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: "rotateY(180deg)",
    },
  };

  return (
    <>
      <Section>
        <Container>
          {/* <MediaWrapper>
            <Img src={img} alt="" />
          </MediaWrapper> */}
          <RegistrationClose style={{ color: "#ffffff", fontWeight: "700", fontSize: "64px", fontFamily: "Hanken Grotesk" }}>
            {props.closeheadline}
          </RegistrationClose>
          <Subtitle style={{ color: "#2b2b2b", fontWeight: "700" }}>
            {props.headline}
          </Subtitle>
          <CardContent>
            {props.data.map((price) => (
              <Card style={styles.card} onClick={() => setFlipped(!flipped)}>
                <CardInfo style={styles.front}>
                  <CardPlan>{price.title}</CardPlan>
                  <CardLength>{price.subtitle}&nbsp;</CardLength>
                  {price.discounted ? (
                    <>
                      <Discount>
                        <Currency>{price.currency}</Currency>
                        {price.discount}
                      </Discount>
                      <CardLength>{price.discountCaption}&nbsp;</CardLength>
                    </>
                  ) : (
                    <>
                      <CardCost>
                        <Currency>{price.currency}</Currency>
                        {price.cost}
                      </CardCost>
                    </>
                  )}
                  {price.inclusions[0] ? (
                    <CardInclusions>
                      <CardInclusion>
                        <FaCheck style={{ color: "#01bf71" }} />
                        &nbsp;{price.inclusions[0]}
                      </CardInclusion>
                      <CardInclusion>
                        <FaCheck style={{ color: "#01bf71" }} />
                        &nbsp;{price.inclusions[1]}
                      </CardInclusion>
                      <CardInclusion>
                        &nbsp;&nbsp;&nbsp;&nbsp;and more ...
                      </CardInclusion>
                    </CardInclusions>
                  ) : (
                    <></>
                  )}
                </CardInfo>
                <CardInfo style={styles.back}>
                  <MediaWrapper style={{ paddingBottom: 20 }}>
                    <Img src={price.qrcode} />
                  </MediaWrapper>

                  <ContentButton
                    primary={props.primary}
                    secondary={props.secondary}
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    onClick={() => {
                      window.open(price.croute);
                    }}
                  >
                    {props.buttonLabelOne}
                  </ContentButton>
                  <ContentButton style={{ marginTop: 20, marginBottom: 20 }}
                    primary={props.primary}
                    secondary={props.secondary}
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    onClick={() => {
                      window.open(price.broute);
                    }}
                  >
                    {props.buttonLabelTwo}
                  </ContentButton>
                </CardInfo>
              </Card>
            ))}
          </CardContent>
        </Container>
      </Section>
    </>
  );
};
export default Ticket;