export const introduction = {
    reverse: false,
    inverse: false,
    topLine: {
        text: 'Introduction',
    },
    headline: "5th GCC FMT Conference",
    description: "UAEFMA is proud to introduce to you the 5th edition of the GCC Financial Markets & Treasury Conference. We look to provide a gate-way for market professionals to take part at the event to gain succinct information on issues affecting the current financial climate in the region & globally.",
    shortDescription: "UAEFMA is proud to introduce to you the 5th edition of the GCC Financial Markets & Treasury Conference.",
    buttonLabel: 'Find More',
    img: require("../assets/img/gcc-updated-white-logo.png"),
    start: 'true',
    primary: false,
    secondary: true,
    route: '/information'
};