import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

import { breakpoints } from './utils/useMediaBreakpoints';

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Axiforma Book", sans-serif;
	background: #2b2b2b;
  }
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1300px;
	margin: auto;
`;

export const CardContainer = styled.div`
	width: 100%;
	max-width: 1300px;
	margin: auto;
`;

export const Section = styled.section`
	background: ${({ inverse }) => (inverse ? '#f4f4f4' : '#2B2B2B')};
	padding: ${({ padding }) => (padding ? padding : '40px 20px')};
	position: ${({ position }) => (position ? position : '')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};

`;

export const CardSection = styled.section`
	padding: ${({ padding }) => (padding ? padding : '35px 20px')};
	margin: ${({ margin }) => (margin ? margin : '')};
	background: ${({ inverse }) => (inverse ? '#f4f4f4' : '#2B2B2B')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
	justify-content: center;
	align-items: center;
	
	${breakpoints("text-align", "", [{ 500: "center" }])}

`;

export const AccordionSection = styled.div`
	background: #f4f4f4;
	padding: 0;
	position: ${({ position }) => (position ? position : '')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
`;

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	justify-content: space-around;

	${breakpoints("flex-direction", "", [{ 800: "column-reverse" }])}

`;

export const ContentColumn = styled(motion.div)`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const TextWrapper = styled.div`
	max-width: 700px;
	padding-top: 2rem;
	justify-content: center;
	align-content: center;
	align-items: center;

	${breakpoints("width", "px", [{ 800: 600 }, { 600: 350 }, { 500: 330 }])}
	
`;

export const MediaWrapper = styled(motion.div)`
	display: flex;
	max-height: 700px;
	position: relative;
	justify-content: center;
	align-items: center;
`;

export const TopLine = styled(motion.div)`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 5px;
	text-transform: uppercase;
	margin-bottom: 10px;
	text-align: left;

    ${breakpoints("text-align", "", [{ 500: "center" }])}
`;

export const BottomLine = styled(motion.div)`
	color: #01bf71;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom: 10px;
	margin-top: 24px;
	text-align: left;

    ${breakpoints("text-align", "", [{ 500: "left" }])}
`;

export const Img = styled(motion.img)`
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 500px;
	z-index: 1;

	${breakpoints("max-height", "", [{ 320: "260px" }, { 960: "280px" }])}
`;

export const Heading = styled(motion.h2)`
	margin-bottom: 25px;
	font-size: 3rem;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ inverse }) => (inverse ? '#313886' : '#f4f4f4')};
	text-align: left;
	
    ${breakpoints("text-align", "", [{ 500: "center" }])}
	${breakpoints("font-size", "rem", [{ 1000: 2.5 }, { 500: 2 }])}
`;

export const Subtitle = styled(motion.p)`
	max-width: 600px;
	margin-bottom: 30px;
	line-height: 24px;
	color: ${({ inverse }) => (inverse ? '#6a6a6a' : '#f4f4f4')};
	text-align: justify;
	
	${breakpoints("max-width", "px", [{ 1200: 555 }])}
`;

export const ContentButton = styled(motion.a)`
background: ${({ primary }) => (primary ? '#313886' : '#F4F4F4')};
color: ${({ primary }) => (primary ? '#f4f4f4' : '#2b2b2b')};
font-size: ${({ fontBig }) => (fontBig ? '20px' : '#16px')};
	border-radius: 10px;
    white-space: nowrap;
    padding: 10px 35px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    padding-top: 13px;
    cursor: pointer;
	text-decoration: none;

    &:hover {
		background: ${({ primary, secondary }) => (primary ? (secondary ? '#ff0000' : '#2b2b2b') : ('#313886'))};
		color: ${({ primary, secondary }) => (primary ? (secondary ? '#ff0000' : '#f4f4f4') : ('#f4f4f4'))};
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }

	&:nth-child(2) {
    margin-top: 24px;
  }
`;

export const ContentButtonRegistration = styled(motion.a)`
background: ${({ primary }) => (primary ? '#313886' : '#F4F4F4')};
color: ${({ primary }) => (primary ? '#f4f4f4' : '#2b2b2b')};
font-size: ${({ fontBig }) => (fontBig ? '20px' : '#16px')};
	border-radius: 10px;
	position: absolute;
	margin-top:24px ;
	left: 50%;
	font-size: 18px;
    white-space: nowrap;
    padding: 10px 35px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    padding-top: 13px;
    cursor: pointer;
	text-decoration: none;

    &:hover {
		background: ${({ primary, secondary }) => (primary ? (secondary ? '#ff0000' : '#2b2b2b') : ('#313886'))};
		color: ${({ primary, secondary }) => (primary ? (secondary ? '#ff0000' : '#f4f4f4') : ('#f4f4f4'))};
        transition: all 0.2s ease-in-out;
        text-decoration: none;
    }
`;


export const ArrowForward = styled(MdArrowForward)`
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    font-size: 20px;
`

export default GlobalStyle;
