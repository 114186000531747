import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import {
    Container,
    Section,
    ContentRow,
    TextWrapper,
    TopLine,
    Heading,
    ContentButton,
    Subtitle,
    MediaWrapper,
    Img,
    ContentColumn,
    ArrowForward,
    ArrowRight
} from '../../../global.style';
import { ButtonWrapper } from './introduction.elements';

export const Introduction = (props) => {
    const { height, width } = useWindowDimensions();
    const initial = { opacity: 0, y: 30 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <Section inverse={props.inverse} ref={ref}>
            <Container>
                <ContentRow reverse={props.reverse}>
                    <ContentColumn>
                        <TextWrapper>
                            <TopLine
                                initial={initial}
                                transition={{ delay: 0.3, duration: 0.6 }}
                                animate={animation}
                            >
                                {props.topLine.text}
                            </TopLine>
                            <Heading
                                initial={initial}
                                transition={{ delay: 0.5, duration: 0.6 }}
                                animate={animation}
                                inverse={props.inverse}
                            >
                                {props.headline}
                            </Heading>
                            <Subtitle
                                initial={initial}
                                transition={{ delay: 0.7, duration: 0.6 }}
                                animate={animation}
                            >
                                {width > 500 ? props.description : props.shortDescription}
                            </Subtitle>
                            <ButtonWrapper
                                initial={initial}
                                transition={{ delay: 1, duration: 0.6 }}
                                animate={animation}
                                inverse={props.inverse}>
                                <ContentButton
                                    primary={props.primary}
                                    secondary={props.secondary}
                                    onMouseEnter={onHover}
                                    onMouseLeave={onHover}
                                    href={props.route}
                                >
                                    {props.buttonLabel}{hover ? <ArrowForward /> : <ArrowRight />}
                                </ContentButton>
                            </ButtonWrapper>
                        </TextWrapper>
                    </ContentColumn>
                    {width > 1100 ? <ContentColumn
                        initial={initial}
                        transition={{ delay: 0.5, duration: 0.6 }}
                        animate={animation}
                    >
                        <MediaWrapper style={{ paddingLeft: "50px" }}>
                            <Img
                                src={props.img}
                                alt={props.alt}
                                transition={{ duration: 0.5 }}
                            />
                        </MediaWrapper>
                    </ContentColumn> : <></>}

                </ContentRow>
            </Container>
        </Section>
    );
};
