import React from 'react';
import { Img, MediaWrapper } from '../../global.style';
import {
    SideBarContainer,
    Icon,
    CloseIcon,
    SideBarWrapper,
    SideBarMenu,
    SideBarLink,
    SideBarButtonWrapper,
    SideBarRoute
} from './sidebar.elements';
import logo from "../../assets/img/gcc-white-small.png";

export const SideBar = ({ isOpen, toggle }) => {
    return (
        <SideBarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>

            <SideBarWrapper>
                <MediaWrapper style={{ width: "50%" }}>
                    <Img src={logo} alt=""/>
                </MediaWrapper>
                <SideBarMenu>
                    <SideBarLink to='/' onClick={toggle}>Home</SideBarLink>
                    <SideBarLink to='information' onClick={toggle}>Information</SideBarLink>
                    <SideBarLink to='agenda' onClick={toggle}>Agenda</SideBarLink>
                    <SideBarLink to='speakers' onClick={toggle}>Speakers</SideBarLink>
                </SideBarMenu>
                <SideBarButtonWrapper>
                    <SideBarRoute to='/tickets'>Register Now</SideBarRoute>
                </SideBarButtonWrapper>
            </SideBarWrapper>
        </SideBarContainer>
    )
}