/*jshint esversion: 6 */
import React from "react";
import "./PaymentMethods.style.scss";

class Card extends React.Component {
  render() {
    return <div className="method">{this.props.children}</div>;
  }
}

class SelectableCard extends React.Component {
  render() {
    var isSelected = this.props.selected ? "selected" : "";
    var className = "selectable " + isSelected;
    return (
      <Card>
        <div className={className} onClick={this.props.onClick}>
          {this.props.children}
          <div className="check-bar"></div>
        </div>
      </Card>
    );
  }
}

class SelectableTitleCard extends React.Component {
  render() {
    var { title, price, selected, newPrice } = this.props;
    return (
      <SelectableCard onClick={this.props.onClick} selected={selected}>
        <div className="payment-method">
          <h1 className="title">{title}</h1>
        </div>
      </SelectableCard>
    );
  }
}

class SelectableCardList extends React.Component {
  constructor(props) {
    super(props);
    var selected = null
    var initialState = {
      selected: selected,
    };
    this.state = initialState;
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      if (props.multiple) {
        var selectedIndexes = prevState.selected;
        var selectedIndex = selectedIndexes.indexOf(index);
        if (selectedIndex > -1) {
          selectedIndexes.splice(selectedIndex, 1);
          props.onChange(selectedIndexes);
        } else {
          if (!(selectedIndexes.length >= props.maxSelectable)) {
            selectedIndexes.push(index);
            props.onChange(selectedIndexes);
          }
        }
        return {
          selected: selectedIndexes,
        };
      } else {
        props.onChange(index);
        return {
          selected: index,
        };
      }
    });
  }

  render() {
    var { contents, multiple } = this.props;

    var content = contents.map((cardContent, i) => {
      var { title, price, selected, newPrice } = cardContent;
      var selected = multiple
        ? this.state.selected.indexOf(i) > -1
        : this.state.selected == i;
      return (
        <SelectableTitleCard
          key={i}
          title={title}
          price={price}
          newPrice={newPrice}
          selected={selected}
          onClick={(e) => this.onItemSelected(i)}
        />
      );
    });
    return (
      <div
        style={{
          display: "inline-flex",
        }}
        className="cardlist"
      >
        {content}
      </div>
    );
  }
}

export default class Example extends React.Component {
  onListChanged(selected) {
    this.setState({
      selected: selected,
    });
    this.props.onChange(selected);
  }
  submit() {
    window.alert("Selected: " + this.state.selected);
  }
  render() {
    return (
      <div className="column">
        <h1 className="title" style={{ textAlign: "left", marginLeft: '15px' }}>{this.props.title}</h1>
        <SelectableCardList
          multiple={this.props.multiple}
          maxSelectable={this.props.maxSelectable}
          contents={this.props.cardContents}
          onChange={this.onListChanged.bind(this)}
        />
      </div>
    );
  }
}

export var paymentMethods = [
  {
    title: "Credit/Debt Card",
  },
  {
    title: "Bank Transfer",
  },
];
