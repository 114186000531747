import React, { useEffect } from "react";
import { Section, Container, AccordionSection } from "../../global.style";
import { StyledTabs, TabPanel, TabItem, useStyles } from "./agenda.elements";
import { Details } from "./details";
import { dayOne } from "../../data/agenda/day1.data";
import { dayTwo } from "../../data/agenda/day2.data";
import { dayThree } from "../../data/agenda/day3.data";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Accordion } from "../Accordion";

export default function AgendaTabs({ inverse }) {
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const classes = useStyles();
    const [value, setValue] = React.useState(id | 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const days = ['13th Sep', '14th Sep', '15th Sep'];

    return (
        <>
            {width > 900 ? (
                <Section inverse={inverse}>
                    <Container>

                        <>
                            <StyledTabs
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Information Tabs"
                            >
                                {days.map((item, index) => (
                                    <TabItem label={item} onClick={() => setValue(index)} />
                                ))}
                            </StyledTabs>

                            <TabPanel value={value} index={0} style={{ justifyContent: "flex-start" }}>
                                <Details {...dayOne} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Details {...dayTwo} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Details {...dayThree} />
                            </TabPanel>
                        </>


                    </Container>
                </Section >
            ) : (
                <AccordionSection>
                    <Accordion />
                </AccordionSection>)}
        </>
    );
}
