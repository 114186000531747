import { Title } from "../../components/Agenda/details.elements";

export const dayTwo = {
    reverse: false,
    inverse: true,
    topLine: "Day 2",
    headline: "14 Sep 2023, Thursday",
    schedule: [
        // [Time, Event Title, Discussion Title, Description]
        ["08:30 - 09:30", "Delegate Registration and Badge Distribution - Networking Exhibition"],
        ["09:30 - 10:00", "Official Inauguration and Opening Speech"],
        // ["10:00 - 11:00", "Panel I", "GCC Economic Outlook", "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."],
        ["10:00 - 10:50", "Panel I - Global macro – growth, inflation, interest rate outlook:", "Return from the tightening monetary policies of the Fed and major central banks and effects on emerging markets. Living in the high rate environment, what to expect."],
        ["10:50 - 11:20", "Coffee Break and Exhibition"],
        ["11:20 - 12:10", "Panel II - Turkey macro-economic challenges and outlook:", "Inflationary cost pressures, higher funding cost, lower local bank access and increased complexity of the regulatory environment were the prior challenges. How the outlook changed for Turkey?"],
        ["12:10 - 13:00", "Panel III - Latest trends in the Turkish debt capital market and opportunities for the wider MENA region"],
        ["13:00 - 13:20", "ICMA update: The latest repo and collateral market developments - Keynote presentation by Alexander Westphal"],
        ["13:20 - 14:20", "Lunch"],
        ["14:20 - 15:00", "Keynote Speaker - The Short Lira Put Option Investment - The Trap of Price Spikes Causing Disastrous Losses – by Prof. Uwe Wystup"],
        ["15:00 - 15:30", "DEI fireside chat - Women in the capital markets: initiatives, challenges and opportunities - UAE/GCC and European perspectives by Sanaa Clausse BenAbdelhadi and Ahoud Al Ali"],
        ["19:30 - 23:00", "Dinner by XM"],
    ],
};