import React from 'react';

import { Container, Section } from '../../global.style';
import {
    ContentRow,
    TopLine,
    MediaWrapper,
    Img,
    ContentColumn,
    FooterLink,
    FooterContact,
    LinkWrapper,
    SocialMediaWrapper,
    SocialIconLink,
    BottomLine
} from './footer.elements';
import { FaFacebook, FaInstagram, FaLinkedin, FaRegCopyright, FaTwitter } from 'react-icons/fa';
import useWindowDimensions from '../../utils/useWindowDimensions';

export const Footer = (props) => {
    const { height, width } = useWindowDimensions();

    return (
        <Section inverse={props.inverse}>
            <Container>
                <ContentRow reverse={props.reverse}>
                    {width > 900 ?
                        <>
                            <ContentColumn>
                                <MediaWrapper>
                                    <Img src={props.img} />
                                </MediaWrapper>
                            </ContentColumn>
                            <ContentColumn style={{ alignItems: "center" }}>
                                <LinkWrapper>
                                    <TopLine>Site Map</TopLine>
                                    <FooterLink to="/information">Information</FooterLink>
                                    <FooterLink to="/information">Travelling To Turkey</FooterLink>
                                    {/* <FooterLink to="/venue">Venue</FooterLink> */}
                                    <FooterLink to="/agenda">Agenda</FooterLink>
                                    {/* <FooterLink to="/contactus">Contact Us</FooterLink> */}
                                </LinkWrapper>
                            </ContentColumn>
                            <ContentColumn>
                                <TopLine>Social Media Links</TopLine>
                                <SocialMediaWrapper>
                                    <SocialIconLink href={props.facebook} target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                                    <SocialIconLink href={props.instagram} target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
                                    <SocialIconLink href={props.twitter} target="_blank" aria-label="Twitter"><FaTwitter /></SocialIconLink>
                                    <SocialIconLink href={props.linkedin} target="_blank" aria-label="LinkedIn"><FaLinkedin /></SocialIconLink>
                                </SocialMediaWrapper>
                            </ContentColumn>
                            <ContentColumn style={{ alignItems: "center" }}>
                                <LinkWrapper>
                                    <TopLine>Contact Us</TopLine>
                                    <FooterContact >Phone: +971 42 566 622</FooterContact>
                                    <FooterContact >Email: info@gccfmt.com</FooterContact>
                                    <FooterContact >Mobile: +971 55 258 9011</FooterContact>
                                </LinkWrapper>
                            </ContentColumn>
                        </> : <>
                            <ContentColumn style={{ alignItems: "center" }}>
                                <LinkWrapper>
                                    <TopLine>Site Map</TopLine>
                                    <FooterLink to="/information">Information</FooterLink>
                                    <FooterLink to="/information">Travelling To Turkey</FooterLink>
                                    {/* <FooterLink to="/venue">Venue</FooterLink> */}
                                    <FooterLink to="/agenda">Agenda</FooterLink>
                                    {/* <FooterLink to="/contactus">Contact Us</FooterLink> */}
                                </LinkWrapper>
                            </ContentColumn>
                            <ContentColumn>
                                <TopLine>Social Media Links</TopLine>
                                <SocialMediaWrapper>
                                    <SocialIconLink href={props.facebook} target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                                    <SocialIconLink href={props.instagram} target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
                                    <SocialIconLink href={props.twitter} target="_blank" aria-label="Twitter"><FaTwitter /></SocialIconLink>
                                    <SocialIconLink href={props.linkedin} target="_blank" aria-label="LinkedIn"><FaLinkedin /></SocialIconLink>
                                </SocialMediaWrapper>
                            </ContentColumn>
                            <ContentColumn style={{ alignItems: "center" }}>
                                <LinkWrapper>
                                    <TopLine>Contact Us</TopLine>
                                    <FooterContact >Phone: +971 42 566 622</FooterContact>
                                    <FooterContact >Email: info@gccfmt.com</FooterContact>
                                    <FooterContact >Mobile: +971 55 258 9011</FooterContact>
                                </LinkWrapper>
                            </ContentColumn>
                        </>
                    }
                </ContentRow>
                <ContentRow style={{ paddingTop: "50px" }}>
                    <BottomLine><FaRegCopyright style={{ paddingBottom: "2px" }} />&nbsp;5<sup>th</sup> GCC FMT {new Date().getFullYear() + 1} . ALL RIGHTS RESERVED</BottomLine>
                </ContentRow>
            </Container>
        </Section>
    );
};
